import React, { Component } from 'react';
import FormularioPadrao from './FormularioPadrao';
import PaisController from '../controllers/PaisController';

export default class PaisView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.controller = new PaisController(this);
    }

    
    render() {
        return (
            <FormularioPadrao
                titulo={this.props.lang.telaDeCadastroDePais.titulo}
                url={'/pais'}
                api={this.props.api}
                lang={this.props.lang}
                ordenacaoPadrao={'Id'}
                permissoes={[1331,null,null,null]}
                getFiltro={this.controller.getFiltro}
                getTitulosDaTabela={this.controller.getTitulosDaTabela}
                getDadosDaTabela={this.controller.getDadosDaTabela}
                renderizarFormulario={this.renderizarFormulario}
                getObjetoDeDados={this.controller.getObjetoDeDados}
                antesDeInserir={this.controller.antesDeInserir}
                antesDeEditar={this.controller.antesDeEditar}
                antesDeSalvar={this.controller.antesDeSalvar}
                antesDeExcluir={this.controller.antesDeExcluir}
                getFormState={() => {
                    return this.state;
                }}
                setFormState={(state, callback) => {
                    return new Promise((resolve) => {
                        this.setState(state, () => {
                            resolve();
                            if (callback) {
                                callback();
                            }
                        });
                    });
                }}
                select={this.props.select}
                itemVazio={{}}
            />
        );
    }
}
