import React from 'react';
import NumberFormat from 'react-number-format';
import '../contents/css/integer-input.css';

export default class IntegerInput extends React.Component {
    render() {
        return (
            <NumberFormat
                className={'form-control integer-input right-align' + (this.props.className ? (' ' + this.props.className) : '')}
                decimalSeparator=','
                thousandSeparator='.'
                defaultValue={this.props.defaultValue}
                onValueChange={this.props.onChange}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key} 
                ref={this.props.ref}
                readOnly={this.props.readOnly}
                placeholder={this.props.placeholder}
            />
        );
    }
}
