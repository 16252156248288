import { FormGroup } from 'react-bootstrap';
import Label from '../../components/Label';
import { showError } from '../../components/Messages';
import Select from '../../components/Select';
import { BaseController } from '../BaseController';
import TextArea from '../../components/TextArea';

export default class ParametroDoSistemaController extends BaseController {
   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeParametrosDoSistema.descricao, width: '50%', orderby: 'Descricao' },
         { titulo: this.lang.telaDeCadastroDeParametrosDoSistema.valor, width: '50%', orderby: 'Valor' },
      ];
   };

   getDadosDaTabela = (item) => {
      let parametro = this.comboParametros().filter((i) => i.nome === item.nome)[0];
      return parametro ? [item.id, parametro.descricao, this.getDescricaoDoValor(item)] : null;
   };

   componenteTextArea = (defaultValue, setValor) => {
      return (
         <FormGroup>
            <Label>{this.lang.telaDeCadastroDeParametrosDoSistema.selecione}</Label>
            <TextArea defaultValue={defaultValue} onChange={(e) => setValor(e.target.value)} />
         </FormGroup>
      );
   };

   getDescricaoDoValor = (item) => {
      var camposObjeto = [];
      var descricao = null;
      const getDescricaoParaObjeto = () => {
         var result = null;
         if (item.preenchido) {
            result = JSON.parse(item.valor);
            result = result.id + ' - ' + result.nome;
         }
         return result;
      };

      const getDescricao = (valor) => {
         var result = null;
         if (valor === 'Sim') {
            result = this.lang.sim;
         } else if (valor === 'Não') {
            result = this.lang.nao;
         } else {
            result = valor;
         }
         return result;
      };

      if (camposObjeto.filter((i) => i === item.nome).length > 0) {
         descricao = getDescricaoParaObjeto();
      } else {
         descricao =
            item.protegido && item.preenchido
               ? this.lang.telaDeCadastroDeParametrosDoSistema.protegido
               : getDescricao(item.valor);
      }
      return descricao;
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let item = this.view.state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeParametrosDoSistema.mensagens.selecioneOParametro);
            reject();
            return;
         }

         var input = {
            grupo: item.grupo,
            ordem: item.ordem ? item.ordem : 0,
            nome: item.nome,
            descricao: item.descricao,
            valor: item.valor,
            protegido: item.protegido,
         };
         if (this.view.state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   comboParametros = () => {
      let comboParametros = this.lang.telaDeCadastroDeParametrosDoSistema.comboParametros;
      let result = [
         // Envio e e-mail
         { nome: 'ServidorSmtp', descricao: comboParametros.servidorSmtp, grupo: 1, ordem: 1 },
         { nome: 'PortaSmtp', descricao: comboParametros.portaSmtp, grupo: 1, ordem: 2 },
         {
            nome: 'UsuarioDoServidorSmtp',
            descricao: comboParametros.usuarioDeAcessoAoServidorSmtp,
            grupo: 1,
            ordem: 3,
            ajuda: comboParametros.usuarioDeAcessoAoServidorSmtp_Ajuda,
         },

         { nome: 'EmailRemetente', descricao: comboParametros.emailRemetente, grupo: 1, ordem: 4 },
         {
            nome: 'SenhaDoServidorSmtp',
            descricao: comboParametros.senhaRemetente,
            grupo: 1,
            ordem: 5,
            protegido: true,
         },
         { nome: 'NomeDoRemetente', descricao: comboParametros.nomeDoRemetente, grupo: 1, ordem: 6 },
         {
            nome: 'UsarSslNoServidorSmtp',
            descricao: comboParametros.usarSsl,
            grupo: 1,
            ordem: 7,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>{this.lang.telaDeCadastroDeParametrosDoSistema.selecione}</Label>
                     <Select
                        name={'UsarSslNoServidorSmtp'}
                        defaultValue={defaultValue}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        options={[
                           { id: 'Não', nome: this.lang.nao },
                           { id: 'Sim', nome: this.lang.sim },
                        ]}
                        onSelect={(i) => {
                           if (i) {
                              setValor(i.id);
                           } else {
                              setValor('Não');
                           }
                        }}
                        allowEmpty={false}
                     />
                  </FormGroup>
               );
            },
         },

         // Infobip
         { nome: 'InfobipApiUrl', descricao: comboParametros.urlDaApiInfobip, grupo: 2, ordem: 1 },
         { nome: 'InfobipApiKey', descricao: comboParametros.chaveDaApiInfobip, grupo: 2, ordem: 2, protegido: true },
         { nome: 'InfobipApiRemetenteWhatsApp', descricao: comboParametros.remetenteWhatsAppDaApiInfobip, grupo: 2, ordem: 3, protegido: false },

         // Controle de localização
         {
            nome: 'LocalizarPorTriangulacao',
            descricao: comboParametros.utilizarTriangulacao,
            grupo: 3,
            ordem: 1,
            componente: (defaultValue, setValor) => {
               return (
                  <FormGroup>
                     <Label>Selecione</Label>
                     <Select
                        name={'LocalizarPorTriangulacao'}
                        defaultValue={defaultValue}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.nome}
                        options={[
                           { id: 'Não', nome: this.lang.nao },
                           { id: 'Sim', nome: this.lang.sim },
                        ]}
                        onSelect={(i) => {
                           if (i) {
                              setValor(i.id);
                           } else {
                              setValor('Não');
                           }
                        }}
                        allowEmpty={false}
                     />
                  </FormGroup>
               );
            },
         },

         // Notificações         
         {
            nome: 'TextoParaNotificacaoDeBateriaFracaViaEmail',
            descricao: comboParametros.textoParaNotificacaoDeBateriaFracaViaEmail,
            grupo: 3,
            ordem: 9,
            componente: this.componenteTextArea,
            ajuda: this.lang.telaDeCadastroDeParametrosDoSistema.ajudaParaTemplateDeNotificacao  + '\n' + this.lang.telaDeCadastroDeParametrosDoSistema.variavelDeBateria,
         },
         {
            nome: 'TextoParaNotificacaoDeBateriaFracaViaSms',
            descricao: comboParametros.textoParaNotificacaoDeBateriaFracaViaSms,
            grupo: 3,
            ordem: 10,
            componente: this.componenteTextArea,
            ajuda: this.lang.telaDeCadastroDeParametrosDoSistema.ajudaParaTemplateDeNotificacao + '\n' + this.lang.telaDeCadastroDeParametrosDoSistema.variavelDeBateria,
         },
         {
            nome: 'templateParaNotificacaoDeBateriaFracaViaWhatsApp',
            descricao: comboParametros.templateParaNotificacaoDeBateriaFracaViaWhatsApp,
            grupo: 3,
            ordem: 11,
            componente: this.componenteTextArea,            
         },    
         {
            nome: 'TextoParaNotificacaoDeBateriaFracaViaMensagemDeVoz',
            descricao: comboParametros.textoParaNotificacaoDeBateriaFracaViaMensagemDeVoz,
            grupo: 3,
            ordem: 12,
            componente: this.componenteTextArea,
            ajuda: this.lang.telaDeCadastroDeParametrosDoSistema.ajudaParaTemplateDeNotificacao + '\n' + this.lang.telaDeCadastroDeParametrosDoSistema.variavelDeBateria,
         },

         {
            nome: 'TextoParaNotificacaoDeGatewayOfflineViaEmail',
            descricao: comboParametros.textoParaNotificacaoDeGatewayOfflineViaEmail,
            grupo: 3,
            ordem: 13,
            componente: this.componenteTextArea,
            ajuda: this.lang.telaDeCadastroDeParametrosDoSistema.ajudaParaTemplateDeNotificacaoDeGateway,
         },
         {
            nome: 'TextoParaNotificacaoDeGatewayOfflineViaSms',
            descricao: comboParametros.textoParaNotificacaoDeGatewayOfflineViaSms,
            grupo: 3,
            ordem: 14,
            componente: this.componenteTextArea,
            ajuda: this.lang.telaDeCadastroDeParametrosDoSistema.ajudaParaTemplateDeNotificacaoDeGateway,
         },
         {
            nome: 'TemplateParaNotificacaoDeGatewayOfflineViaWhatsApp',
            descricao: comboParametros.templateParaNotificacaoDeGatewayOfflineViaWhatsApp,
            grupo: 3,
            ordem: 16,
            componente: this.componenteTextArea,            
         }, 
         {
            nome: 'TextoParaNotificacaoDeGatewayOfflineViaMensagemDeVoz',
            descricao: comboParametros.textoParaNotificacaoDeGatewayOfflineViaMensagemDeVoz,
            grupo: 3,
            ordem: 16,
            componente: this.componenteTextArea,
            ajuda: this.lang.telaDeCadastroDeParametrosDoSistema.ajudaParaTemplateDeNotificacaoDeGateway,
         },
      ];
      return result;
   };
}
