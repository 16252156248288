import React, { Component } from 'react';
import FormularioPadrao from './FormularioPadrao';
import AuditoriaController from '../controllers/AuditoriaController';
import { Col, Row, FormGroup } from 'react-bootstrap';
import { buildQueryString, dateTimeToString, prepareSearchable, updateState } from '../utils/Functions';
import Label from '../components/Label';
import TextInput from '../components/TextInput';
import TextArea from '../components/TextArea';
import Button from '../components/Button';
import Select from '../components/Select';
import FuncionarioTecnometricsView from './Tecnometrics/FuncionarioTecnometricsView';
import DateInput from '../components/DateInput';
import TimeInput from '../components/TimeInput';
import UsuarioDeEmpresaView from './Empresa/UsuarioDeEmpresaView';
import SessionManager from '../models/SessionManager';

export default class AuditoriaView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         acoes: [],
         dataInicial: new Date(),
         horaInicial: '00:00',
         dataFinal: new Date(),
         horaFinal: '23:59',
      };
      this.controller = new AuditoriaController(this);

      let sessionManager = new SessionManager();
      this.isTecnometrics = sessionManager.isUsuarioTecnometrics();
      this.isEmpresa = sessionManager.isUsuarioDeEmpresa();
   }

   componentDidMount() {
      this.props.api.get('/auditoria/acoes').then((result) => {
         this.setState({ acoes: result });
      });
   }

   renderizarFormulario = () => {
      return (
         <React.Fragment>
            <Row>
               <Col sm={4} md={4} lg={2}>
                  <FormGroup>
                     <Label>{'Código'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>
               <Col sm={8} md={8} lg={3}>
                  <FormGroup>
                     <Label>{'Data'}</Label>
                     <TextInput readOnly defaultValue={dateTimeToString(this.state.itemSelecionado.data)} />
                  </FormGroup>
               </Col>
               <Col sm={6} md={6} lg={3}>
                  <FormGroup>
                     <Label>{'Usuário'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.nomeDoUsuario} />
                  </FormGroup>
               </Col>
               <Col sm={6} md={6} lg={4}>
                  <FormGroup>
                     <Label>{'Ação'}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.acao.descricao} />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{'Detalhes'}</Label>
                     <TextArea
                        style={{ fontSize: 11 }}
                        readOnly
                        defaultValue={this.state.itemSelecionado.descricao}
                        rows={12}
                     />
                  </FormGroup>
               </Col>
            </Row>
         </React.Fragment>
      );
   };

   renderizarFiltros = () => {
      return (
         <div>
            <Row>
            {this.isTecnometrics && (
                  <Col sm={6} md={6} lg={2}>
                     <FormGroup>
                        <Label>Usuário</Label>
                        <Select
                           name={'usuario'}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nomeDeUsuario}
                           onSelect={(i) => {
                              this.setState({ usuario: i });
                           }}
                           formularioPadrao={(select) => {
                              return (
                                 <FuncionarioTecnometricsView api={this.props.api} select={select} lang={this.props.lang} />
                              );
                           }}
                           noDropDown={true}
                           readOnlyColor='#ffff'
                           getFilterUrl={(text) =>
                              '/funcionariotecnometrics/fast' +
                              buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                        />
                     </FormGroup>
                  </Col>
               )}

               {this.isEmpresa && (
                  <Col sm={6} md={6} lg={2}>
                     <FormGroup>
                        <Label>Usuário</Label>
                        <Select
                           name={'usuario'}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.nomeDeUsuario}
                           onSelect={(i) => {
                              this.setState({ usuario: i });
                           }}
                           formularioPadrao={(select) => {
                              return (
                                 <UsuarioDeEmpresaView api={this.props.api} select={select} lang={this.props.lang} />
                              );
                           }}
                           noDropDown={true}
                           readOnlyColor='#ffff'
                           getFilterUrl={(text) =>
                              '/usuariodeempresa/fast' +
                              buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                           }
                        />
                     </FormGroup>
                  </Col>
               )}

               <Col sm={6} md={6} lg={2}>
                  <FormGroup>
                     <Label>Ação</Label>
                     {this.state.acoes && (
                        <Select
                           name={'acao'}
                           options={this.state.acoes}
                           getKeyValue={(i) => i.id}
                           getDescription={(i) => i.descricao}
                           onSelect={(i) => {
                              this.setState({ acao: i });
                           }}
                           nullText={''}
                        />
                     )}
                  </FormGroup>
               </Col>

               <Col sm={6} md={3} lg={2}>
                  <FormGroup>
                     <Label>{'Data inicial'}</Label>
                     <DateInput
                        defaultValue={this.state.dataInicial}
                        lang={this.props.lang}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col sm={6} md={2} lg={1}>
                  <FormGroup>
                     <Label>{'hora'}</Label>
                     <TimeInput
                        defaultValue={this.state.horaInicial}
                        lang={this.props.lang}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.horaInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col sm={6} md={3} lg={2}>
                  <FormGroup>
                     <Label>{'Data final'}</Label>
                     <DateInput
                        defaultValue={this.state.dataFinal}
                        lang={this.props.lang}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.dataFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col sm={6} md={2} lg={1}>
                  <FormGroup>
                     <Label>{'hora'}</Label>
                     <TimeInput
                        defaultValue={this.state.horaFinal}
                        lang={this.props.lang}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.horaFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col sm={6} md={2} lg={2}>
                  <FormGroup>
                     <Label>&nbsp;</Label>
                     <Button text={'Filtrar'} onClick={this.form.filtrar} />
                  </FormGroup>
               </Col>
            </Row>
         </div>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={'Auditoria'}
            url={'/auditoria'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            ref={(c) => (this.form = c)}
            permissoes={[1501, null, null, null]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            esconderFiltro={true}
            renderizarFiltros={this.renderizarFiltros}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{}}
         />
      );
   }
}
