import * as moment from 'moment';
import crypto from 'crypto';
import { showError } from '../components/Messages';

export const stringToDate = (stringDate) => {
   if (!stringDate) return null;
   let result = moment(
      stringDate.substr(6, 4) + '-' + stringDate.substr(3, 2) + '-' + stringDate.substr(0, 2)
   ).toDate();
   return result;
};

export function addDays(date, days) {
   var result = new Date(date);
   result.setDate(result.getDate() + days);
   return result;
}

export const mesPorExtenso = (data, lang) => {
   var result = [
      lang && lang.janeiro ? lang.janeiro : 'Janeiro',
      lang && lang.fevereiro ? lang.fevereiro : 'Fevereiro',
      lang && lang.marco ? lang.marco : 'Março',
      lang && lang.abril ? lang.abril : 'Abril',
      lang && lang.maio ? lang.maio : 'Maio',
      lang && lang.junho ? lang.junho : 'Junho',
      lang && lang.julho ? lang.julho : 'Julho',
      lang && lang.agosto ? lang.agosto : 'Agosto',
      lang && lang.setembro ? lang.setembro : 'Setembro',
      lang && lang.outubro ? lang.outubro : 'Outubro',
      lang && lang.novembro ? lang.novembro : 'Novembro',
      lang && lang.dezembro ? lang.dezembro : 'Dezembro',
   ][data.getMonth()];
   return result;
};

export const isNumeric = (num) => {
   return !isNaN(num);
};

export const isString = (value) => {
   return typeof value === 'string' || value instanceof String;
};

export const dateToString = (stringDate, lang) => {
   if (!stringDate) return null;
   let date = new Date(stringDate);
   let result = moment(date).format(lang && lang.dateFormat ? lang.dateFormat : 'DD/MM/YYYY');
   return result;
};

export const dateTimeToString = (stringDate, lang) => {
   if (!stringDate) return null;
   let date = new Date(stringDate);
   let result = moment(date).format(lang && lang.dateTimeFormat ? lang.dateTimeFormat : 'DD/MM/YYYY HH:mm:ss');
   return result;
};

export const timeToString = (stringDate, lang) => {
   if (!stringDate) return null;
   let dateTime = dateTimeToString(stringDate)
   let result = dateTime.split(' ')[1];
   return result;
};

export const formatDate = (date, dateFormat = 'YYYY-MM-DD') => {
   if (!date) return null;
   let result = moment(date).format(dateFormat);
   return result;
};

export const formatDateTime = (date, dateFormat = 'YYYY-MM-DD HH:mm:ss') => {
   if (!date) return null;
   let result = moment(date).format(dateFormat);
   return result;
};

export const pad2 = (n) => {
   return (n < 10 ? '0' : '') + n;
};

export const pad = (number, length, char = '0') => {
   return number.toString().padStart(length, '0');
};

export const numberToCurrencyString = (number, lang) => {
   if (number === null || number === undefined) return null;
   if (isNaN(number)) return null;
   let result = new Intl.NumberFormat(lang && lang.lang_id ? lang.lang_id : 'pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
   }).format(number);
   return result;
};

export const create_UUID = () => {
   var dt = new Date().getTime();
   var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
   });
   return uuid;
};

export const numberToString = (number, minimumFractionDigits = 0, maximumFractionDigits = 6, lang) => {
   if (number === null || number === undefined) return null;
   if (isNaN(number)) return null;
   let result = new Intl.NumberFormat(lang && lang.lang_id ? lang.lang_id : 'pt-BR', {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
   }).format(number);
   return result;
};

export const numberToString2 = (number, minimumFractionDigits = 0, lang) => {
   if (number === null || number === undefined) return null;
   if (isNaN(number)) return null;
   let result = new Intl.NumberFormat(lang && lang.lang_id ? lang.lang_id : 'pt-BR', {
      minimumFractionDigits: minimumFractionDigits,
   }).format(number);
   return result;
};

export const replaceAll = (string, search, replace) => {
   return string.split(search).join(replace);
};

export const removeMask = (text) => {
   if (!text) return text;
   let result = replaceAll(text, '.', '');
   result = replaceAll(result, '-', '');
   return result;
};

export const getEnderecoCompleto = (endereco) => {
   if (!endereco) return null;

   if (!endereco.pais || (endereco.pais && endereco.pais.codigo === '+55')) {
      return replaceAll(
         [
            endereco.logradouro,
            endereco.numero,
            endereco.complemento,
            endereco.bairro,
            endereco.cep ? 'CEP ' + endereco.cep : '',
            endereco.cidade.nome + ' / ' + endereco.cidade.estado.uf,
         ].join(', '),
         ', , ',
         ', '
      );
   } else {
      return replaceAll([endereco.linha1, endereco.linha2, endereco.linha3].join('\n'), ', , ', ', ').trim();
   }
};

export const horaMaiorOuIgual = (a, b) => {
   let result = parseInt(a.replace(':', '')) >= parseInt(b.replace(':', ''));
   return result;
};

export const horaMenorOuIgual = (a, b) => {
   let result = parseInt(a.replace(':', '')) <= parseInt(b.replace(':', ''));
   return result;
};

export const calcularDiferencaEntreHorasEmMinutos = (horaInicial, horaFinal) => {
   let data = moment();
   let horaInicial1 = moment(data.format('YYYY-MM-DD ' + horaInicial));
   let horaFinal1 = moment(data.format('YYYY-MM-DD ' + horaFinal));
   let result = horaFinal1.diff(horaInicial1, 'minutes');
   return result;
};

export const converterHorasParaMinutos = (hora) => {
   let result = calcularDiferencaEntreHorasEmMinutos('00:00:00', hora);
   return result;
};

export const converterMinutosParaHora = (minutos) => {
   let data = moment();
   let dataBase = moment(data.format('YYYY-MM-DD'));
   dataBase.add(minutos, 'minutes');
   let result = dataBase.format('HH:mm');
   return result;
};

export const getNomeDoDiaDaSemana = (diaDaSemana, lang) => {
   let nomes = [
      lang && lang.domingo ? lang.domingo : 'Domingo',
      lang && lang.segunda ? lang.segunda : 'Segunda-feira',
      lang && lang.terca ? lang.terca : 'Terça-feira',
      lang && lang.quarta ? lang.quarta : 'Quarta-feira',
      lang && lang.quinta ? lang.quinta : 'Quinta-feira',
      lang && lang.sexta ? lang.sexta : 'Sexta-feira',
      lang && lang.sabado ? lang.sabado : 'Sábado',
   ];
   let result = nomes[diaDaSemana - 1];
   return result;
};

export const getTelefones = (item) => {
   let result = '';
   item.telefones.forEach((telefone) => {
      result += telefone.numeroComDDD + ', ';
   });
   if (result[result.length - 2] === ',') {
      result = result.substr(0, result.length - 2);
   }
   return result;
};

export const generatePassword = (numberOnly, length) => {
   var charset = numberOnly ? 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' : '0123456789',
      retVal = '';
   for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
   }
   return retVal;
};

export const retirarAcentos = (str) => {
   if (!str) {
      return '';
   }
   let com_acento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';

   let sem_acento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
   let novastr = '';
   for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < com_acento.length; a++) {
         if (str.substr(i, 1) === com_acento.substr(a, 1)) {
            novastr += sem_acento.substr(a, 1);
            troca = true;
            break;
         }
      }
      if (troca === false) {
         novastr += str.substr(i, 1);
      }
   }
   return novastr;
};

export const inputToUpper = (e) => {
   var start = e.target.selectionStart;
   // var end = e.target.selectionEnd;
   e.target.value = ('' + e.target.value).toUpperCase();
   e.target.setSelectionRange(start, start);
   e.preventDefault();
};

export const updateState = (component, updateStateFunction) => {
   return new Promise((resolve, reject) => {
      try {
         let state = component.state;
         if (!state) {
            state = {};
         }
         if (updateStateFunction) {
            if (updateStateFunction.then) {
               updateStateFunction(state).then(() => {
                  component.setState(state, resolve);
               });
            } else {
               updateStateFunction(state);
               component.setState(state, resolve);
            }
         } else {
            resolve();
         }
      } catch (e) {
         reject(e);
      }
   });
};

export const buildQueryString = (pageSize, skip, orderBy, filter) => {
   let query = '?';
   if (pageSize) query += 'pagesize=' + pageSize.toString() + '&';
   if (skip) query += 'skip=' + skip.toString() + '&';
   if (orderBy) query += 'orderBy=' + orderBy.toString() + '&';
   if (filter) {
      let filterString = Object.keys(filter)
         .map((key) => {
            let result = null;
            let name = encodeURIComponent(key);
            let value = encodeURIComponent(filter[key]);
            if (value !== 'null' && value !== 'undefined') {
               result = `${name}=${value}`;
            }
            return result;
         })
         .filter((i) => i)
         .join('&');
      query += filterString ? 'query=' + encodeURIComponent(filterString) + '&' : '';
   }
   return query.substr(0, query.length - 1);
};

export const objectToQueryString = (filter) => {
   let query = '';

   if (filter) {
      query = Object.keys(filter)
         .map((key) => {
            let result = null;
            let name = encodeURIComponent(key);
            let value = encodeURIComponent(filter[key]);
            if (value !== 'null' && value !== 'undefined') {
               result = `${name}=${value}`;
            }
            return result;
         })
         .filter((i) => i)
         .join('&');
   }
   return query;
};

export const rsaEncrypt = (data, key) => {
   return crypto.publicEncrypt(
      {
         format: 'pem',
         key: key,
         padding: crypto.constants.RSA_PKCS1_OAEP_PADDING,
         oaepHash: 'sha1',
      },
      Buffer.from(data)
   );
};

export const validarEndereco = (paisObrigatorio, endereco, lang, reject) => {
   if (paisObrigatorio && !endereco.pais) {
      showError(lang.endereco.mensagens.informeOPais);
      reject();
      return false;
   }

   if ((paisObrigatorio && endereco.pais.codigo === '+55') || !paisObrigatorio) {
      if (!endereco.cep) {
         showError(lang.endereco.mensagens.informeOCEP);
         reject();
         return false;
      }

      if (!endereco.logradouro) {
         showError(lang.endereco.mensagens.informeOLogradouro);
         reject();
         return false;
      }

      if (!endereco.numero) {
         showError(lang.endereco.mensagens.informeONumeroDoEndereco);
         reject();
         return false;
      }

      if (!endereco.bairro) {
         showError(lang.endereco.mensagens.informeOBairro);
         reject();
         return false;
      }

      if (!endereco.cidade) {
         showError(lang.endereco.mensagens.informeACidade);
         reject();
         return false;
      }
   }
   return true.valueOf;
};

export const validarTelefone = (paisPadrao, telefone, lang, reject) => {
   if (!telefone.pais) {
      telefone.pais = paisPadrao;
   }

   if (!telefone.pais) {
      showError(lang.telefone.mensagens.informeOPaisDoTelefone);
      reject();
      return false;
   }

   if (!telefone.ddd) {
      showError(lang.telefone.mensagens.informeODddDoTelefone);
      reject();
      return false;
   }

   if (isNaN(telefone.ddd)) {
      showError(lang.telefone.mensagens.informeApenasNumerosParaODdd);
      reject();
      return false;
   }

   if (!telefone.numero) {
      showError(lang.telefone.mensagens.informeONumeroDoTelefone);
      reject();
      return false;
   }

   if (isNaN(telefone.numero)) {
      showError(lang.telefone.mensagem.informeApenasNumerosParaOTelefone);
      reject();
      return false;
   }

   return true;
};

export const generateUUID = () => {
   // Public Domain/MIT
   var d = new Date().getTime(); //Timestamp
   var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
         //Use timestamp until depleted
         r = (d + r) % 16 | 0;
         d = Math.floor(d / 16);
      } else {
         //Use microseconds since page-load if supported
         r = (d2 + r) % 16 | 0;
         d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
   });
};

export const toEntityReference = (i) => {
   let result = null;
   if (i && i.id !== null && i.id !== undefined) {
      result = { id: i.id };
   }
   return result;
};

export const getBase64ImageSize = (base64) => {
   return new Promise((resolve, reject) => {
      let img = document.createElement('img');
      img.onload = function () {
         resolve({
            height: img.height,
            width: img.width,
         });
      };
      img.onerror = function (e) {
         console.error(e);
      };
      img.src = base64;
   });
};

export const emptyStrIfNull = (s, emptyStr = '') => {
   return s ? s : emptyStr;
};

export const prepareSearchable = (texto) => {
   let reusult = texto;
   if (reusult) {
      reusult = ('' + retirarAcentos(reusult)).toUpperCase();
      var charsToRemove = ['@', ',', '.', ';', "'", '\\', '/', '-', '_', '+'];
      charsToRemove.forEach((c) => {
         reusult = replaceAll(reusult, c, '');
      });
   }
   return reusult;
};

export const downloadBase64File = (contentBase64, fileName, dataType) => {
   const linkSource = `data:${dataType};base64,${contentBase64}`;
   const downloadLink = document.createElement('a');
   document.body.appendChild(downloadLink);

   downloadLink.href = linkSource;
   downloadLink.target = '_self';
   downloadLink.download = fileName;
   downloadLink.click();
};