import React, { Component } from 'react';
import { LayoutParams } from './../config/LayoutParams';
import '../contents/css/cabecalho-tabela-formulario-padrao.css';
import '../contents/css/tabela-formulario-padrao.css';
import Button from '../components/Button';
import { buildQueryString } from '../utils/Functions';
import { ButtonGroup } from 'react-bootstrap';
import { faAngleDoubleDown, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let mostrarCodigo = false;

export default class ListaPadrao extends Component {
   constructor(props) {
      super(props);
      const { renderizarFiltros, getTitulosDaTabela, getDadosDaTabela, getFiltro } = props;

      this.setState = props.setFormState;
      this.getState = props.getFormState;

      this.renderizarCodigo = this.renderizarCodigo.bind(this);
      this.getTitulo = this.getTitulo.bind(this);
      this.getLista = this.getLista.bind(this);
      this.getNavegador = this.getNavegador.bind(this);
      this.cancelarClick = this.cancelarClick.bind(this);
      this.filtrar = this.filtrar.bind(this);
      this.getCabecalhos = this.getCabecalhos.bind(this);
      this.renderizarFiltros = renderizarFiltros ? renderizarFiltros : this.renderizarFiltros;
      this.getFiltro = getFiltro ? getFiltro : this.getFiltro;
      this.getTitulosDaTabela = getTitulosDaTabela ? getTitulosDaTabela : this.getTitulosDaTabela;
      this.getDadosDaTabela = getDadosDaTabela ? getDadosDaTabela : this.getDadosDaTabela;
   }

   componentDidMount() {
      try {
         if (this.props.ref) {
            this.props.ref(this);
         }

         let clientHeight = 800;

         this.setState(
            {
               itemSelecionado: null,
               itens: null,
               filtro: {},
               ordenacao: this.props.ordenacaoPadrao,
               tamanhoDaPagina: Math.trunc(clientHeight / 36.8),
            },
            () => {
               if (!this.props.iniciarVazio) {
                  this.filtrar();
               }
            }
         );
      } catch (e) {
         console.error(e);
      }
   }

   /*
         Esse formulário padrão é um componente renderizado na tela.
         Para utilizado implemente os métodos a seguir e passe eles como propriedade no componente.
         As consultas são realizadas via POST        
         Por exemplo:

         <ListaPadrao 
            url={'https://api/controller/action'}
            getFiltro={this.getFiltro}
            getTitulosDaTabela={this.getTitulosDaTabela}
            getDadosDaTabela={this.getDadosDaTabela}   
         />      
    */

   renderizarFiltros(sender) {
      // * OBGRIGATÓRIO
      // Implemente esse método para desenhar os campos de filtro na tela
   }

   getFiltro(sender) {
      // * OBGRIGATÓRIO
      // Implemente esse método para retornar o objeto a ser passado via POST para a url de pesquisa
   }

   getTitulosDaTabela() {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de string com os títulos para a tabela
   }

   getDadosDaTabela(item) {
      // * OBGRIGATÓRIO
      // Implemente esse método no para retornar um array de valores a ser usado na linha da tabela.
      // Este método é chamado passando registro por registro.
   }

   isMobile = () => {
      return window.screen.width <= 600;
   };

   getNavegador() {
      return (
         <div
            style={{
               width: '100%',
               display: 'flex',
               justifyContent: 'flex-end',
               padding: '10px 12px 10px 10px',
            }}
         >
            {!this.props.esconderBotaoFechar && (
               <div style={{ width: 150, textAlign: 'left' }}>
                  <Button
                     variant='secondary'
                     onClick={this.cancelarClick}
                     style={{ width: 150 }}
                     text={this.props.lang.formularioPadrao.fechar}
                  />
               </div>
            )}

            {this.getState().itens ? (
               <React.Fragment>
                  <div
                     style={{
                        width: '100%',
                        textAlign: 'left',
                        paddingTop: 10,
                        paddingLeft: 5,
                     }}
                  >
                     {this.getState().quantidadeTotalDeDados ? (
                        <div>
                           <span>{this.props.lang.formularioPadrao.mostrando}&nbsp; </span>
                           <span>{this.getState().quantidadeDeDados}</span>
                           <span>&nbsp;{this.props.lang.formularioPadrao.de}&nbsp;</span>
                           <span>{this.getState().quantidadeTotalDeDados}</span>
                        </div>
                     ) : null}
                  </div>
                  <ButtonGroup className='mr-2' style={{ minWidth: 120 }}>
                     <Button
                        title={this.props.lang.formularioPadrao.carregarMais}
                        text=''
                        icon={<FontAwesomeIcon icon={faAngleDown} />}
                        variant='secondary'
                        onClick={() => this.navegar(1)}
                        disabled={!this.getState().podeAvancar}
                        style={{
                           cursor: this.getState().podeAvancar ? 'pointer' : 'not-allowed',
                           padding: 0,
                        }}
                     />
                     <Button
                        title={this.props.lang.formularioPadrao.carregarTodos}
                        text=''
                        icon={<FontAwesomeIcon icon={faAngleDoubleDown} />}
                        variant='secondary'
                        onClick={() => this.navegar(2)}
                        disabled={!this.getState().podeCarregarTodos}
                        style={{
                           cursor: this.getState().podeCarregarTodos ? 'pointer' : 'not-allowed',
                           padding: 0,
                        }}
                     />
                  </ButtonGroup>
               </React.Fragment>
            ) : null}
         </div>
      );
   }

   cancelarClick() {
      return new Promise((resolve) => {
         if (this.props.select && this.props.select.aoCancelar) {
            this.props.select.aoCancelar();
         }
         if (this.props.aoCancelar) {
            this.props.aoCancelar();
         }
         if (this.props.aoFechar) {
            this.props.aoFechar();
         }
         resolve();
      });
   }

   filtrar() {
      return new Promise((resolve, reject) => {
         try {
            this.setState(
               {
                  itens: [],
                  quantidadeDeDados: null,
                  quantidadeTotalDeDados: null,
               },
               () => {
                  this.getFiltro()
                     .then((filtro) => {
                        this.setState({ carregando: true, vazio: true });
                        let query = '';
                        if (filtro) {
                           if (this.props.estruturaPadrao) {
                              if (filtro && filtro.id) {
                                 query = '?id=' + filtro.id.toString;
                              } else {
                                 var orderBy = this.getState().ordenacao;
                                 query = buildQueryString(this.getState().tamanhoDaPagina, null, orderBy, filtro);
                              }
                           } else {
                              query = Object.keys(filtro)
                                 .map((key) => {
                                    var result = null;
                                    if (encodeURIComponent(filtro[key]) !== 'null') {
                                       result = `${encodeURIComponent(key)}=${encodeURIComponent(filtro[key])}`;
                                    }
                                    return result;
                                 })
                                 .join('&');
                              if (query) {
                                 query = '?' + query;
                              }
                           }
                        }
                        return this.props.api
                           .get(this.props.url + query)
                           .then((data) => {
                              let quantidadeDeDados = null;
                              let quantidadeTotalDeDados = null;
                              let podeAvancar = null;
                              let podeCarregarTodos = null;
                              let vazio = null;

                              if (this.props.estruturaPadrao) {
                                 quantidadeDeDados = data.pageSize;
                                 quantidadeTotalDeDados = data.count;
                                 podeAvancar = data.count >= this.getState().tamanhoDaPagina;
                                 podeCarregarTodos = data.count >= this.getState().tamanhoDaPagina;
                                 vazio = !data || data.count === 0;
                              } else {
                                 quantidadeDeDados = data && data.length;
                                 quantidadeTotalDeDados = data && data.length;
                                 podeAvancar = false;
                                 podeCarregarTodos = false;
                                 vazio = !data || data.length === 0;
                              }

                              this.setState(
                                 {
                                    itens: this.props.estruturaPadrao ? data.items : data,
                                    quantidadeDeDados: quantidadeDeDados,
                                    quantidadeTotalDeDados: quantidadeTotalDeDados,
                                    podeAvancar: podeAvancar,
                                    podeCarregarTodos: podeCarregarTodos,
                                    vazio: vazio,
                                    carregando: false,
                                 },
                                 resolve
                              );
                           })
                           .catch(reject);
                     })
                     .catch(reject);
               }
            );
         } catch (e) {
            reject(e);
         }
      });
   }

   navegar(opcao) {
      let query = '';
      let state = this.getState();
      var orderBy = state.ordenacao;

      this.getFiltro().then((filtro) => {
         if (filtro && filtro.id) {
            query = '?id=' + filtro.id.toString;
         } else {
            query = buildQueryString(this.getState().tamanhoDaPagina, state.itens.length.toString(), orderBy, filtro);

            if (opcao === 1) {
               query = buildQueryString(
                  this.getState().tamanhoDaPagina,
                  state.itens.length.toString(),
                  orderBy,
                  filtro
               );
            } else {
               query = buildQueryString(null, null, orderBy, filtro);
               state.itens = [];
            }
         }

         this.setState(
            {
               quantidadeDeDados: null,
               quantidadeTotalDeDados: null,
            },
            () => {
               this.props.api
                  .getAll(this.props.url + query)
                  .then((data) => {
                     state.itens.push(...data.items);
                     this.setState({
                        itens: state.itens,
                        quantidadeDeDados: state.itens.length,
                        quantidadeTotalDeDados: data.count,
                        podeAvancar: data.count > state.itens.length,
                        podeCarregarTodos: data.count > state.itens.length,
                        vazio: !data || data.count === 0,
                     });
                  })
                  .catch((e) => console.error(e));
            }
         );
      });
   }

   renderizarCodigo(codigo) {
      return (
         <td className='codigo'>
            <div>{codigo}</div>
         </td>
      );
   }

   getTitulo() {
      return (
         <div
            style={{
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               color: LayoutParams.colors.corSecundaria,
               borderRadius: 0,
               marginLeft: 0,
               marginRight: 0,
            }}
         >
            <div
               style={{
                  paddingTop: 0,
                  paddingLeft: 10,
                  paddingRight: 10,
                  height: 45,
                  display: 'flex',
               }}
            >
               <div
                  style={{
                     display: 'table-cell',
                     width: '0',
                     overflowX: 'visible',
                     fontSize: 22,
                     fontWeight: 500,
                     whiteSpace: 'nowrap',
                  }}
               >
                  <div>{this.props.titulo}</div>
               </div>
            </div>
         </div>
      );
   }

   getFiltros() {
      return (
         <div
            style={{
               paddingTop: 8,
               paddingLeft: 10,
               paddingRight: 14,
            }}
         >
            {this.renderizarFiltros(this)}
         </div>
      );
   }

   getCabecalhos() {
      const isMobile = this.isMobile();

      var titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();
      if (!titulos || isMobile) {
         return null;
      }
      return (
         <div className='div-cabecalho-tabela-formulario-padrao'>
            <table className='cabecalho-tabela-formulario-padrao'>
               <thead>
                  <tr>
                     {titulos.map((item, index) => {
                        if (!mostrarCodigo && index === 0) return null;
                        else
                           return (
                              <td
                                 key={index}
                                 className={item.className}
                                 style={{
                                    cursor: item.orderby && this.props.estruturaPadrao ? 'pointer' : 'default',
                                    width: item.width,
                                 }}
                                 onClick={() => {
                                    if (item.orderby && this.props.estruturaPadrao) {
                                       let ordenacao = item.orderby;
                                       if (ordenacao === this.getState().ordenacao) {
                                          ordenacao += ' desc';
                                       }
                                       this.setState({ ordenacao: ordenacao }, () => {
                                          this.filtrar();
                                       });
                                    }
                                 }}
                              >
                                 {item.titulo}
                              </td>
                           );
                     })}
                  </tr>
               </thead>
            </table>
         </div>
      );
   }

   getLista() {
      const isMobile = this.isMobile();
      const titulos = this.getTitulosDaTabela && this.getTitulosDaTabela();
      const textoDosTitulos = titulos.map((i) => i.titulo);
      let result = null;

      if (!titulos) {
         return null;
      }

      const tamanhos = titulos.map((i) => i.width);
      const classes = titulos.map((i) => i.className);
      let itens = this.getState().itens;

      if (isMobile) {
         result = this.getState().vazio ? (
            <table className='tabela-formulario-padrao'>
               <tbody>
                  <tr>
                     <td style={{ width: '100%', textAlign: 'center', color: '#999' }}>
                        <span>
                           {this.getState().carregando
                              ? this.props.lang.formularioPadrao.mensagens.carregando
                              : this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}
                        </span>
                     </td>
                  </tr>
               </tbody>
            </table>
         ) : (
            itens &&
            itens.map((item, rowIndex) => {
               return !item ? null : (
                  <div
                     key={rowIndex}
                     className={this.props.select ? 'noselect' : null}
                     style={{
                        cursor: this.props.select ? 'pointer' : 'default',
                        border: '1px solid #999',
                        borderRadius: 5,
                        marginBottom: 7,
                        marginRight: 10,
                        padding: 3,
                     }}
                  >
                     {this.getDadosDaTabela(item).map((dado, campoIndex) => {
                        return (
                           <div key={campoIndex} style={{ display: 'flex', flexDirection: 'row' }}>
                              <div style={{ display: 'table-cell' }}>
                                 <strong>{textoDosTitulos[campoIndex]}:&nbsp;</strong>
                              </div>
                              <div style={{ display: 'table-cell' }}>
                                 <span style={{ wordWrap: 'anywhere' }}>{dado}</span>
                              </div>
                           </div>
                        );
                     })}
                  </div>
               );
            })
         );
      } else {
         result = result = this.getState().vazio ? (
            <table className='tabela-formulario-padrao'>
               <tbody>
                  <tr>
                     <td style={{ width: '100%', textAlign: 'center', color: '#999' }}>
                        <span>
                           {this.getState().carregando
                              ? this.props.lang.formularioPadrao.mensagens.carregando
                              : this.props.lang.formularioPadrao.mensagens.nenhumRegistroEncontrado}
                        </span>
                     </td>
                  </tr>
               </tbody>
            </table>
         ) : (
            <table className='tabela-formulario-padrao table-hover'>
               <tbody>
                  {itens != null &&
                     itens.map((item, rowIndex) => {
                        return !item ? null : (
                           <tr
                              key={rowIndex}
                              className={this.props.select ? 'noselect' : null}
                              style={{
                                 cursor: this.props.select ? 'pointer' : 'default',
                              }}
                           >
                              {[
                                 this.getDadosDaTabela(item).map((dado, campoIndex) => {
                                    if (!mostrarCodigo && campoIndex === 0) return null;
                                    else
                                       return (
                                          <td
                                             key={campoIndex}
                                             className={classes[campoIndex]}
                                             style={{ width: tamanhos[campoIndex] }}
                                          >
                                             {dado}
                                          </td>
                                       );
                                 }),
                              ]}
                           </tr>
                        );
                     })}
               </tbody>
            </table>
         );
      }
      return result;
   }

   getAcoes = () => {
      return (
         <div
            style={{
               display: 'flex',
               flexDirection: 'row',
               paddingBottom: 10,
               paddingLeft: 10,
            }}
         >
            <div style={{ display: 'table-cell' }}>
               <Button
                  style={{ width: 200 }}
                  text={this.props.lang.pesquisar}
                  inProgressText={this.props.lang.pesquisando}
                  onClickAsync={this.filtrar}
               />
            </div>
         </div>
      );
   };

   render() {
      return (
         <div
            id='listaPadrao'
            style={{
               display: 'flex',
               flexDirection: 'column',
               maxHeight: '100%',
               overflowX: 'hidden',
               width: '100%',
               maxWidth: '100%',
               height: '100%',
            }}
         >
            {!this.props.esconderTitulo && this.getTitulo()}
            {this.getFiltros()}
            {this.getAcoes()}
            {this.getCabecalhos()}
            <div className='div-tabela-formulario-padrao'>{this.getLista()}</div>
            {this.getNavegador()}
         </div>
      );
   }
}
