import React from 'react';
import { FormControl } from 'react-bootstrap';
import '../contents/css/text-input.css';

export default class TextArea extends React.Component {
    render() {
        return (
            <FormControl
                className={'text-input' + (this.props.className ? ' ' + this.props.className : '')}                  
                as="textarea"              
                rows={this.props.rows ? this.props.rows : 3}
                style={this.props.style}
                name={this.props.name}
                id={this.props.id}
                key={this.props.key}
                ref={this.props.ref}
                defaultValue={this.props.defaultValue}
                onChange={(e) => {
                    let oldSelectionStart = e.target.selectionStart;
                    let oldSelectionEnd = e.target.selectionEnd;
     
                    if (this.props.upperCase) {
                        e.target.value = ('' + e.target.value).toUpperCase();
                    }
                    if (this.props.lowerCase) {
                       e.target.value = ('' + e.target.value).toLowerCase();
                    }
     
                    e.target.selectionStart = oldSelectionStart;
                    e.target.selectionEnd = oldSelectionEnd;
     
                    if (this.props.onChange) {
                       this.props.onChange(e);
                    }
                }}
                readOnly={this.props.readOnly}
            />
        );
    }
}
