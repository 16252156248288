import React, { Component, Fragment, useState } from 'react';
import { Stage, Layer, Rect, Image, Transformer, Label, Text, Line as KonvaLine } from 'react-konva';
import useImage from 'use-image';
import { showConfirm, showError, showInfo } from '../../components/Messages';
import { LayoutParams } from '../../config/LayoutParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import gatewayGreen from './../../contents/img/gateway-green.png';
import { Col, FormGroup, Modal, Row } from 'react-bootstrap';
// import gatewayRed from './../../contents/img/gateway-red.png';
// import gatewayOrange from './../../contents/img/gateway-orange.png';
import Button from '../../components/Button';
import DecimalInput from '../../components/DecimalInput';
import { generateUUID, numberToString2, updateState } from '../../utils/Functions';
import { faArrowLeft, faArrowRight, faBolt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import SubCadastro from '../../components/SubCadastro';
import Select from '../../components/Select';
import TextInput from '../../components/TextInput';
import CheckBox from '../../components/CheckBox';
import Line from '../../components/Line';
import { RgbaPicker } from 'react-rgba-color-picker';
import '../../contents/css/rgba-vertical-picker.css';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
//import Konva from 'konva';

let timer = 0;
let delay = 200;
let prevent = false;
const corDeDestaque = 'RGBA(0,0,0,0.05)';
let LANG = {};

export default class ConfigurarMapaView extends Component {
   constructor(props) {
      super(props);
      LANG = this.props.lang;
      const queryString = window.location.toString().split('#')[1].split('?')[1];
      const query = new URLSearchParams(queryString);
      const id = query.get('id');
      this.state = {
         mapa: null,
         id: id,
         mostrarDescricao: true,
         mostrarFundo: true,
         mostrarLocais: true,
         mostrarPerfilDeVisualizacao: false,
         panningEnabled: true,
         ajusteDeTamanho: 1,
         carregouImagemDoMapa: false,
      };
   }

   componentDidMount = () => {
      window.addEventListener('resize', () => {
         this.setState({ contrleFechado: false }, () => {
            let ajustarATela = this.state.ajustarATela;
            this.setState({ ajustarATela: null }, () => this.setState({ ajustarATela: ajustarATela }));
         });
      });

      window.addEventListener('ontogglemenu', (showMenu) => {
         let ajustarATela = this.state.ajustarATela;
         this.setState({ ajustarATela: null }, () => this.setState({ ajustarATela: ajustarATela }));
      });

      this.props.api.get('/mapa?id=' + this.state.id).then((result) => {
         this.setState(
            {
               mapa: result.items[0],
            },
            () => {
               if (this.state.mapa.imagem) {
                  this.props.api
                     .get('/file?filename=' + this.state.mapa.imagem.nome, true, 'arraybuffer')
                     .then((data) => {
                        try {
                           const base64 = Buffer.from(data, 'binary').toString('base64');
                           const imageUrl = 'data:image/png;base64, ' + base64;
                           this.imageUrl = imageUrl;
                           this.setState({ carregouImagemDoMapa: true });
                        } catch (e) {
                           console.error(e);
                           showError(e.toString());
                        }
                     });
               }
            }
         );
      });
   };

   doDoubleClickAction(item, action) {
      if (action) {
         action(item);
      }
   }

   handleClick(item, action) {
      let self = this;
      timer = setTimeout(function () {
         if (!prevent) {
            self.onRowClick(item, action);
         }
         prevent = false;
      }, delay);
   }

   handleDoubleClick(item, action) {
      clearTimeout(timer);
      prevent = true;
      this.doDoubleClickAction(item, action);
   }

   getLocalPorCoordenada = (coordenada) => {
      let result = null;

      let x = coordenada.x;
      let y = coordenada.y;

      let retangulos = this.state.mapa.areas
         .filter((i) => i.forma === 'RETANGULO')
         .filter((i) => i.coordenadas.length === 2);

      if (retangulos.length > 0) {
         let areas = retangulos.filter(
            (i) =>
               x >= i.coordenadas[0].x && y >= i.coordenadas[0].y && x <= i.coordenadas[1].x && y <= i.coordenadas[1].y
         );
         let area = null;
         if (areas.length > 0) {
            area = areas[0];
         }
         if (area != null) {
            result = area.local;
         }
      }
      return result;
   };

   controlesFechado = () => {
      return (
         <div id='controles' style={{ minWidth: 35, maxWidth: 35, minHeight: '100%' }}>
            <table>
               <thead style={{ backgroundColor: LayoutParams.colors.fundoCinza }}>
                  <tr>
                     <th style={{ width: '100%' }}>
                        <div
                           style={{ border: '2px solid #666', borderRadius: 2 }}
                           onClick={() => {
                              this.setState({ contrleFechado: false }, () => {
                                 let ajustarATela = this.state.ajustarATela;
                                 this.setState({ ajustarATela: null }, () =>
                                    this.setState({ ajustarATela: ajustarATela })
                                 );
                              });
                           }}
                        >
                           <FontAwesomeIcon
                              title={LANG.telaDeConfiguracaoDeMapa.mostrarOpcoes}
                              style={{ fontSize: 20, paddingTop: 2, marginLeft: 5, marginRight: 5, color: '#666' }}
                              cursor='pointer'
                              icon={faArrowRight}
                           />
                        </div>
                     </th>
                  </tr>
               </thead>
            </table>
         </div>
      );
   };

   getCor = (cor) => {
      let result = {
         r: 255,
         g: 255,
         b: 255,
         a: 1,
      };
      if (cor) {
         cor = cor.replace('RGBA(', '');
         cor = cor.replace(')', '');
         cor = cor.replace(' ', '');
         let corParts = cor.split(',');
         result = {
            r: corParts[0],
            g: corParts[1],
            b: corParts[2],
            a: corParts[3],
         };
      }
      return result;
   };

   controles = () => {
      return (
         <div id='controles' style={{ minWidth: 200, maxWidth: 200, minHeight: '100%' }}>
            <table>
               <thead style={{ backgroundColor: LayoutParams.colors.fundoCinza }}>
                  <tr>
                     <th style={{ width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                           <div style={{ display: 'table-cell', width: '100%' }}>{this.props.lang.telaDeConfiguracaoDeMapa.visualizacao}</div>
                           <div
                              style={{ display: 'table-cell', border: '2px solid #666', borderRadius: 2 }}
                              onClick={() => {
                                 this.setState({ contrleFechado: true }, () => {
                                    let ajustarATela = this.state.ajustarATela;
                                    this.setState({ ajustarATela: null }, () =>
                                       this.setState({ ajustarATela: ajustarATela })
                                    );
                                 });
                              }}
                           >
                              <FontAwesomeIcon
                                 title={LANG.telaDeConfiguracaoDeMapa.esconderOpcoes}
                                 style={{ fontSize: 20, paddingTop: 2, marginLeft: 5, marginRight: 5, color: '#666' }}
                                 cursor='pointer'
                                 icon={faArrowLeft}
                              />
                           </div>
                        </div>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td style={{ width: '100%' }}>
                        <CheckBox
                           defaultChecked={this.state.mostrarFundo}
                           label={LANG.telaDeConfiguracaoDeMapa.mostrarFundoDaArea}
                           onChange={(checked) => {
                              this.setState({ mostrarFundo: checked });
                           }}
                        />
                     </td>
                  </tr>
                  <tr>
                     <td style={{ width: '100%' }}>
                        <CheckBox
                           defaultChecked={this.state.mostrarDescricao}
                           label={LANG.telaDeConfiguracaoDeMapa.mostrarDescricaoDaArea}
                           onChange={(checked) => {
                              this.setState({ mostrarDescricao: checked });
                           }}
                        />
                     </td>
                  </tr>
                  <tr>
                     <td style={{ width: '100%' }}>
                        <CheckBox
                           defaultChecked={this.state.ajustarATela}
                           label={LANG.telaDeConfiguracaoDeMapa.ajustarATela}
                           onChange={(checked) => {
                              this.setState({ ajustarATela: checked });
                           }}
                        />
                     </td>
                  </tr>
                  <tr>
                     <td style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                           <div style={{}}>{this.props.lang.telaDeConfiguracaoDeMapa.escala}&nbsp;</div>
                           <div>
                              <Line color={'red'} width={80} marginTop={8} marginLeft={8} height={5} />
                           </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                           {this.state.mapa && (
                              <Fragment>
                                 {numberToString2(this.state.mapa.escalaPx) + 'px = '}
                                 &nbsp;
                                 <DecimalInput
                                    style={{ height: 20, width: 70, fontSize: 14 }}
                                    defaultValue={this.state.mapa.escalaM}
                                    onChange={(v) => {
                                       updateState(this, (state) => {
                                          state.mapa.escalaM = v.floatValue;
                                       });
                                    }}
                                 />
                                 {'m'}
                              </Fragment>
                           )}
                        </div>
                     </td>
                  </tr>
               </tbody>
               <thead style={{ backgroundColor: '#ffffff' }}>
                  <tr style={{ backgroundColor: '#ffffff' }}>
                     <th style={{ width: '100%', backgroundColor: '#ffffff', paddingBottom: 0 }}>
                        <div style={{ display: 'flex' }}>
                           <div style={{ display: 'table-cell', width: '50%', textAlign: 'center' }}>
                              <div
                                 style={{
                                    borderTop: '2px solid rgb(206, 212, 218)',
                                    borderLeft: '2px solid rgb(206, 212, 218)',
                                    borderRight: '2px solid rgb(206, 212, 218)',
                                    borderTopRightRadius: 4,
                                    borderTopLeftRadius: 4,
                                    cursor: 'pointer',
                                    backgroundColor: this.state.mostrarLocais ? null : LayoutParams.colors.fundoCinza,
                                 }}
                                 onClick={() => {
                                    this.setState({
                                       mostrarLocais: true,
                                       mostrarPerfilDeVisualizacao: false,
                                    });
                                 }}
                              >
                                 <span>{LANG.telaDeConfiguracaoDeMapa.locais}</span>
                              </div>
                           </div>
                           <div style={{ display: 'table-cell', width: '50%', textAlign: 'center' }}>
                              <div
                                 style={{
                                    borderTop: '2px solid rgb(206, 212, 218)',
                                    borderLeft: '2px solid rgb(206, 212, 218)',
                                    borderRight: '2px solid rgb(206, 212, 218)',
                                    borderTopRightRadius: 4,
                                    borderTopLeftRadius: 4,
                                    cursor: 'pointer',
                                    backgroundColor: this.state.mostrarPerfilDeVisualizacao
                                       ? null
                                       : LayoutParams.colors.fundoCinza,
                                 }}
                                 onClick={() => {
                                    this.setState({
                                       mostrarLocais: false,
                                       mostrarPerfilDeVisualizacao: true,
                                    });
                                 }}
                              >
                                 <span>{LANG.telaDeConfiguracaoDeMapa.visualizacao}</span>
                              </div>
                           </div>
                        </div>
                     </th>
                  </tr>
               </thead>

               {this.state.mostrarLocais && (
                  <tbody>
                     {this.state.mapa.locais &&
                        this.state.mapa.locais.map((item, index) => (
                           <tr
                              key={index}
                              style={{
                                 backgroundColor:
                                    this.state.areaSelecionada &&
                                    this.state.areaSelecionada.local &&
                                    this.state.areaSelecionada.local.id === item.local.id
                                       ? corDeDestaque
                                       : null,
                              }}
                           >
                              <td style={{ width: '100%' }}>
                                 <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100%' }}>{item.local.nome}</div>
                                    <div>
                                       <div
                                          style={{
                                             backgroundColor: item.local.cor,
                                             height: 20,
                                             width: 20,
                                             border: '1px solid black',
                                          }}
                                          onDoubleClick={() =>
                                             this.handleDoubleClick(item, (i) => {
                                                let tamanho = this.state.mapa.largura * 0.2;
                                                let x1 = this.state.mapa.largura * 0.5 - tamanho * 0.5;
                                                let y1 = this.state.mapa.altura * 0.5 - tamanho * 0.5;
                                                let x2 = x1 + tamanho;
                                                let y2 = y1 + tamanho;

                                                this.state.mapa.areas.push({
                                                   uid: generateUUID(),
                                                   forma: 'RETANGULO',
                                                   local: item.local,
                                                   coordenadas: [
                                                      { x: x1, y: y1 },
                                                      { x: x2, y: y2 },
                                                   ],
                                                });
                                                this.setState({ rectangles: this.state.rectangles });
                                             })
                                          }
                                       />
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        ))}
                  </tbody>
               )}

               {this.state.mostrarPerfilDeVisualizacao && (
                  <tbody>
                     <tr>
                        <td style={{ width: '100%' }}>
                           <div style={{ display: 'flex' }}>
                              <div style={{ display: 'table-cell', width: '100%' }}>
                                 <span>{LANG.telaDeConfiguracaoDeMapa.perfilDeVisualizacao}</span>
                              </div>
                              <div style={{ display: 'table-cell' }}>
                                 <FontAwesomeIcon
                                    title={LANG.telaDeConfiguracaoDeMapa.adicionarPerfilDeVisualizacao}
                                    style={{
                                       fontSize: 23,
                                       paddingTop: 2,
                                       marginLeft: 5,
                                       marginRight: 5,
                                       color: '#666',
                                    }}
                                    cursor='pointer'
                                    icon={faPlusCircle}
                                    onClick={() => {
                                       this.setState({
                                          adicionandoPerfilDeVisualizacao: true,
                                          mostrarColorPicker: true,
                                       });
                                    }}
                                 />
                              </div>
                           </div>
                        </td>
                     </tr>
                     {this.state.adicionandoPerfilDeVisualizacao && (
                        <tr>
                           <td style={{ width: '100%' }}>
                              <div style={{}}>
                                 <span>{LANG.telaDeConfiguracaoDeMapa.descricao}</span>
                                 <TextInput
                                    style={{ height: 22, width: '100%', fontSize: 14 }}
                                    onChange={(e) => {
                                       this.setState({ descricao: e.target.value });
                                    }}
                                 />

                                 <span>{LANG.telaDeConfiguracaoDeMapa.cor}</span>
                                 <TextInput
                                    style={{ height: 22, width: '100%', fontSize: 14 }}
                                    defaultValue={this.state.cor}
                                    onChange={(e) => {
                                       if (this.state.mostrarCampoCor) {
                                          this.setState({ mostrarColorPicker: false }, () => {
                                             updateState(this, (state) => {
                                                state.cor = e.target.value;
                                             }).then(() => this.setState({ mostrarColorPicker: true }));
                                          });
                                       }
                                    }}
                                 />
                                 <br />
                                 {this.state.mostrarColorPicker && (
                                    <div className='rgba-vertical-picker'>
                                       <RgbaPicker
                                          style={{ margin: 2 }}
                                          color={this.getCor(this.state.cor)}
                                          onChange={(c) => {
                                             if (this.state.mostrarColorPicker) {
                                                this.setState({ mostrarCampoCor: false }, () => {
                                                   updateState(this, (state) => {
                                                      state.cor =
                                                         'RGBA( ' + c.r + ', ' + c.g + ', ' + c.b + ', ' + c.a + ')';
                                                   }).then(() => this.setState({ mostrarCampoCor: true }));
                                                });
                                             }
                                          }}
                                       />
                                    </div>
                                 )}

                                 <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'table-cell' }}>
                                       <Button
                                          text={'OK'}
                                          style={{
                                             height: 23,
                                             backgroundColor: LayoutParams.colors.fundoCinza,
                                             borderBlockColor: LayoutParams.colors.fundoCinza,
                                             color: '#666',
                                             borderColor: '#666',
                                             marginTop: 3,
                                             display: 'table-cell',
                                             marginRight: 3,
                                             width: 60,
                                             fontSize: 13,
                                          }}
                                          onClick={() => {
                                             if (!this.state.descricao) {
                                                showError(LANG.telaDeConfiguracaoDeMapa.mensagens.informeADescricao);
                                                return;
                                             }

                                             if (!this.state.cor) {
                                                showError(LANG.telaDeConfiguracaoDeMapa.mensagens.informeACor);
                                                return;
                                             }

                                             if (
                                                this.state.mapa.perfisDeVisualizacao &&
                                                this.state.mapa.perfisDeVisualizacao.filter(
                                                   (i) => i.descricao === this.state.descricao
                                                ).length > 0
                                             ) {
                                                showError(
                                                   LANG.telaDeConfiguracaoDeMapa
                                                      .jaExisteUmPerfilDeVisualizacaoDeComEssaDescricao
                                                );
                                                return;
                                             }

                                             updateState(this, (state) => {
                                                if (!state.mapa.perfisDeVisualizacao) {
                                                   state.mapa.perfisDeVisualizacao = [];
                                                }

                                                let tamanho = this.state.mapa.largura * 0.2;
                                                let x1 = this.state.mapa.largura * 0.5 - tamanho * 0.5;
                                                let y1 = this.state.mapa.altura * 0.5 - tamanho * 0.5;
                                                let x2 = x1 + tamanho;
                                                let y2 = y1 + tamanho;

                                                state.mapa.perfisDeVisualizacao.push({
                                                   forma: 'RETANGULO',
                                                   descricao: state.descricao,
                                                   cor: state.cor,
                                                   coordenadas: [
                                                      { x: x1, y: y1 },
                                                      { x: x2, y: y2 },
                                                   ],
                                                });

                                                state.mostrarColorPicker = false;
                                                state.adicionandoPerfilDeVisualizacao = false;
                                                state.cor = null;
                                                state.descricao = null;
                                             });
                                          }}
                                       />
                                    </div>
                                    <div style={{ display: 'table-cell' }}>
                                       <Button
                                          text={LANG.telaDeConfiguracaoDeMapa.cancelar}
                                          style={{
                                             height: 23,
                                             backgroundColor: LayoutParams.colors.fundoCinza,
                                             borderBlockColor: LayoutParams.colors.fundoCinza,
                                             color: '#666',
                                             borderColor: '#666',
                                             marginTop: 3,
                                             display: 'table-cell',
                                             marginRight: 3,
                                             width: 80,
                                             fontSize: 13,
                                          }}
                                          onClick={() => {
                                             this.setState({
                                                mostrarColorPicker: false,
                                                adicionandoPerfilDeVisualizacao: false,
                                                cor: null,
                                                descricao: null,
                                             });
                                          }}
                                       />
                                    </div>
                                 </div>
                              </div>
                           </td>
                        </tr>
                     )}

                     {this.state.mapa.perfisDeVisualizacao &&
                        this.state.mapa.perfisDeVisualizacao.map((item, index) => (
                           <tr
                              key={index}
                              style={{
                                 backgroundColor:
                                    this.state.perfilDeVisualizacaoSelecionado &&
                                    this.state.perfilDeVisualizacaoSelecionado.descricao &&
                                    this.state.perfilDeVisualizacaoSelecionado.descricao === item.descricao
                                       ? corDeDestaque
                                       : null,
                              }}
                           >
                              <td style={{ width: '100%' }}>
                                 <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '100%' }}>{item.descricao}</div>
                                    <div>
                                       <div
                                          style={{
                                             backgroundColor: item.cor,
                                             height: 20,
                                             width: 20,
                                             border: '1px solid black',
                                          }}
                                       />
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        ))}
                  </tbody>
               )}
            </table>
            {this.state.mostrarLocais && (
               <table>
                  <thead style={{ backgroundColor: LayoutParams.colors.fundoCinza }}>
                     <tr>
                        <th style={{ width: '100%' }}>Gateways</th>
                     </tr>
                  </thead>
                  <tbody>
                     {this.state.mapa.gateways.map((item, index) => (
                        <tr
                           key={index}
                           style={{
                              backgroundColor:
                                 this.state.macDoGatewaySelecionado &&
                                 item.gateway.mac === this.state.macDoGatewaySelecionado
                                    ? corDeDestaque
                                    : null,
                           }}
                        >
                           <td style={{ width: '100%' }}>
                              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                 <div style={{ width: '100%' }}>
                                    {item.gateway.mac +
                                       (item.gateway.patrimonio ? ' (' + item.gateway.patrimonio + ')' : '')}
                                 </div>
                                 {item.gateway.local && (
                                    <div style={{ backgroundColor: '#FFFFFF' }}>
                                       <div
                                          style={{
                                             backgroundColor: item.gateway.local.cor,
                                             height: 20,
                                             width: 20,
                                             border: '1px solid black',
                                          }}
                                          onDoubleClick={() =>
                                             this.handleDoubleClick(item, (i) => {
                                                this.state.mapa.areas.push({
                                                   uid: generateUUID(),
                                                   forma: 'RETANGULO',
                                                   local: item.local,
                                                   coordenadas: [
                                                      { x: 50, y: 50 },
                                                      { x: 200, y: 200 },
                                                   ],
                                                });
                                                this.setState({ rectangles: this.state.rectangles });
                                             })
                                          }
                                       />
                                    </div>
                                 )}
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            )}
         </div>
      );
   };

   toolbar = () => {
      return (
         <div
            style={{
               borderBottom: '1px solid #999',
               width: '100%',
               backgroundColor: LayoutParams.colors.fundoCinza,
               paddingBottom: 3,
            }}
         >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
               <div style={{ minWidth: 60, maxWidth: 60, paddingTop: 2 }}>
                  {this.state.uidDaAreaSelecionada && (
                     <Fragment>
                        <FontAwesomeIcon
                           title={this.props.lang.telaDeConfiguracaoDeMapa.excluirAreaSelecionada}
                           style={{ fontSize: 23, paddingTop: 2, marginLeft: 5, marginRight: 5 }}
                           cursor='pointer'
                           icon={faTrashAlt}
                           onClick={() => {
                              let mapa = this.state.mapa;
                              let indexToDelete = -1;
                              mapa.areas.map((area, index) => {
                                 if (area.uid === this.state.uidDaAreaSelecionada) {
                                    indexToDelete = index;
                                 }
                                 return true;
                              });
                              if (indexToDelete >= 0) {
                                 mapa.areas.splice(indexToDelete, 1);
                                 this.setState({ mapa: mapa });
                              }
                           }}
                        />
                        <FontAwesomeIcon
                           title={this.props.lang.telaDeConfiguracaoDeMapa.notificacoesEAcoes}
                           style={{ fontSize: 23, paddingTop: 2, marginLeft: 5, marginRight: 5 }}
                           cursor='pointer'
                           icon={faBolt}
                           onClick={() => {
                              this.setState({ mostrarAcoes: true });
                           }}
                        />
                        <Modal
                           show={this.state.mostrarAcoes}
                           scrollable={true}
                           size={'lg'}
                           onHide={() => {}}
                           onKeyDown={(e) => {
                              if (e.keyCode === 27) this.setState({ mostrarAcoes: false });
                           }}
                        >
                           <Modal.Body
                              style={{
                                 overflow: 'hidden',
                                 display: 'flex',
                                 position: 'relative',
                                 fontSize: 13,
                                 padding: '0 0 0 0',
                              }}
                           >
                              {this.cadastroDeAcoes()}
                           </Modal.Body>
                        </Modal>
                     </Fragment>
                  )}

                  {this.state.perfilDeVisualizacaoSelecionado && (
                     <Fragment>
                        <FontAwesomeIcon
                           title={this.props.lang.telaDeConfiguracaoDeMapa.excluirPerfilDeVisualizacaoSelecionado}
                           style={{ fontSize: 23, paddingTop: 2, marginLeft: 5, marginRight: 5 }}
                           cursor='pointer'
                           icon={faTrashAlt}
                           onClick={() => {
                              let mapa = this.state.mapa;
                              let indexToDelete = -1;
                              mapa.perfisDeVisualizacao.map((perfil, index) => {
                                 if (perfil.descricao === this.state.perfilDeVisualizacaoSelecionado.descricao) {
                                    indexToDelete = index;
                                 }
                                 return true;
                              });
                              if (indexToDelete >= 0) {
                                 mapa.perfisDeVisualizacao.splice(indexToDelete, 1);
                                 this.setState({ mapa: mapa });
                              }
                           }}
                        />
                     </Fragment>
                  )}
               </div>
               <div style={{ width: '100%', textAlign: 'right', paddingTop: 2 }}>
                  <Button
                     text={LANG.telaDeConfiguracaoDeMapa.cancelar}
                     style={{
                        height: 27,
                        width: 100,
                        marginTop: 1,
                        display: 'table-cell',
                        marginLeft: 3,
                        marginRight: 3,
                        //fontWeight: 'bold',
                     }}
                     onClick={() => {
                        showConfirm(
                           LANG.telaDeConfiguracaoDeMapa.mensagens.desejaRealmenteCancelarAsAlteracoes,
                           () => {
                              window.history.back();
                           },
                           null,
                           LANG.sim,
                           LANG.nao
                        );
                     }}
                  />
                  <Button
                     text={LANG.telaDeConfiguracaoDeMapa.salvar}
                     imProgressText={LANG.telaDeConfiguracaoDeMapa.salvando}
                     style={{
                        height: 27,
                        width: 100,
                        marginLeft: 3,
                        marginTop: 1,
                        marginRight: 3,
                        display: 'table-cell',
                        //fontWeight: 'bold',
                     }}
                     onClick={() => {
                        let input = this.state.mapa;
                        input.escalaPx = input.escalaPx.toPrecision(6);
                        input.escalaX1 = input.escalaX1.toPrecision(6);
                        input.escalaY1 = input.escalaY1.toPrecision(6);
                        input.escalaX2 = input.escalaX2.toPrecision(6);
                        input.escalaY2 = input.escalaY2.toPrecision(6);

                        this.props.api
                           .put('/mapa', this.state.mapa)
                           .then((result) => {})
                           .then(() => {
                              showInfo(LANG.telaDeConfiguracaoDeMapa.mensagens.salvoComSucesso).then(() => {
                                 window.history.back();
                              });
                           });
                     }}
                  />
               </div>
            </div>
         </div>
      );
   };

   getScale = () => {
      var result = {
         x: 1,
         y: 1,
      };
      if (this.divMapa && this.state.ajustarATela) {
         if (this.state.mapa.largura > this.divMapa.clientWidth) {
            let s = 1 / (this.state.mapa.largura / this.divMapa.clientWidth);
            result = {
               x: s,
               y: s,
            };
         } else if (this.state.mapa.largura < this.divMapa.clientWidth) {
            let s = 1 / (this.state.mapa.largura / this.divMapa.clientWidth);
            result = {
               x: s,
               y: s,
            };
         }
      }
      return result;
   };

   getPanningEnabled = () => {
      let result = true;
      if (
         this.state.uidDaAreaSelecionada ||
         this.state.macDoGatewaySelecionado ||
         this.state.uidDoPerfilDeVisualizacaoSelecionado ||
         this.state.escalaSelecionada
      ) {
         result = false;
      }
      return result;
   };

   planta = () => {
      const checkDeselect = (e) => {
         const clicouNoVazio = e.target === e.target.getStage();
         const alterandoTamanho =
            e.target &&
            e.target.parent &&
            e.target.parent &&
            e.target.parent.name &&
            e.target.parent.name() === 'transformer';
         const estaMovendoGateway = e.target && e.target.id && e.target.id() === this.state.macDoGatewaySelecionado;
         const estaMovendoArea = e.target && e.target.id && e.target.id() === this.state.uidDaAreaSelecionada;
         const estaMovendoPerfilDeVisualizacao =
            e.target && e.target.id && e.target.id() === this.state.uidDoPerfilDeVisualizacaoSelecionado;
         const estaMovendoEscala = e.target && e.target.id && e.target.id() === 'escala';

         if (
            clicouNoVazio ||
            !(
               alterandoTamanho ||
               estaMovendoGateway ||
               estaMovendoArea ||
               estaMovendoPerfilDeVisualizacao ||
               estaMovendoEscala
            )
         ) {
            this.setState({
               uidDaAreaSelecionada: null,
               areaSelecionada: null,
               escalaSelecionada: null,
               perfilDeVisualizacaoSelecionado: null,
               uidDoPerfilDeVisualizacaoSelecionado: null,
               macDoGatewaySelecionado: null,
            });
         }
      };

      let initialScale = null;

      if (this.state.mapa && this.divMapa) {
         initialScale = this.divMapa.clientWidth / this.state.mapa.largura;
         if (initialScale > 1) {
            initialScale = 1;
         }
      }

      //Konva.pixelRatio = 0.5;

      return (
         this.state.mapa && (
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '3px solid #666',
                  width: '100%',
                  height: '100%',
                  maxWidth: '100%',
                  overflow: 'hidden',
               }}
            >
               {this.toolbar()}
               <div
                  id='mapa'
                  style={{
                     width: '100%',
                     height: '100%',
                     maxHeight: '100%',
                     minWidth: '100%',
                     maxWidth: '100%',
                     overflow: 'scroll',
                  }}
                  ref={(c) => {
                     this.divMapa = c;
                  }}
               >
                  {this.state.mapa && this.divMapa && (
                     <TransformWrapper
                        panning={{
                           disabled: !this.getPanningEnabled(),
                        }}
                        minScale={0}
                        initialScale={initialScale}
                        wheel={{ step: 0.05 }}
                        centerOnInit={true}
                     >
                        <TransformComponent
                           contentStyle={{
                              width: this.state.mapa.largura,
                              height: this.state.mapa.altura,
                           }}
                           wrapperStyle={{
                              width: '100%',
                              height: '100%',
                           }}
                        >
                           <Stage
                              width={
                                 this.state.ajustarATela && this.divMapa
                                    ? this.divMapa.clientWidth
                                    : this.state.mapa.largura
                              }
                              height={this.state.mapa.altura}
                              onMouseDown={checkDeselect}
                              onTouchStart={checkDeselect}
                              scale={this.getScale()}
                           >
                              <Layer>
                                 {this.state.carregouImagemDoMapa && (
                                    <MapImage src={this.state.mostrarFundo ? this.imageUrl : null} />
                                 )}

                                 {this.state.mostrarLocais &&
                                    this.state.mapa.areas.map((area, i) => {
                                       let result = null;
                                       if (area.forma === 'RETANGULO') {
                                          let coordenadaInicial = area.coordenadas[0];
                                          if (!coordenadaInicial) {
                                             coordenadaInicial = { x: 20, y: 20 };
                                          }
                                          let coordenadaFinal = area.coordenadas[1];
                                          if (!coordenadaFinal) {
                                             coordenadaFinal = { x: 40, y: 40 };
                                          }
                                          result = (
                                             <Rectangle
                                                key={i}
                                                id={area.uid}
                                                shapeProps={{
                                                   uid: area.uid,
                                                   x: coordenadaInicial.x,
                                                   y: coordenadaInicial.y,
                                                   height: coordenadaFinal.y - coordenadaInicial.y,
                                                   width: coordenadaFinal.x - coordenadaInicial.x,
                                                   fill: area.local.cor,
                                                }}
                                                isSelected={area.uid === this.state.uidDaAreaSelecionada}
                                                text={this.state.mostrarDescricao ? area.local.nome : null}
                                                onSelect={() => {
                                                   this.setState({
                                                      uidDaAreaSelecionada: area.uid,
                                                      areaSelecionada: area,
                                                      macDoGatewaySelecionado: null,
                                                      escalaSelecionada: null,
                                                   });
                                                }}
                                                onChange={(newAttrs) => {
                                                   let coordenadaInicial = { x: newAttrs.x, y: newAttrs.y };
                                                   let coordenadaFinal = {
                                                      x: newAttrs.x + newAttrs.width,
                                                      y: newAttrs.y + newAttrs.height,
                                                   };
                                                   let mapa = this.state.mapa;
                                                   mapa.areas[i].coordenadas = [coordenadaInicial, coordenadaFinal];
                                                   this.setState({ mapa: mapa });
                                                }}
                                                onClick={() => {
                                                   this.setState({
                                                      areaSelecionada: area,
                                                      uidDaAreaSelecionada: area.uid,
                                                      macDoGatewaySelecionado: null,
                                                      escalaSelecionada: null,
                                                   });
                                                }}
                                             />
                                          );
                                       }
                                       return result;
                                    })}

                                 {this.state.mostrarPerfilDeVisualizacao &&
                                    this.state.mapa.perfisDeVisualizacao &&
                                    this.state.mapa.perfisDeVisualizacao.map((perfilDeVisualizacao, i) => {
                                       let result = null;
                                       if (perfilDeVisualizacao.forma === 'RETANGULO') {
                                          let coordenadaInicial = perfilDeVisualizacao.coordenadas[0];
                                          if (!coordenadaInicial) {
                                             coordenadaInicial = { x: 20, y: 20 };
                                          }
                                          let coordenadaFinal = perfilDeVisualizacao.coordenadas[1];
                                          if (!coordenadaFinal) {
                                             coordenadaFinal = { x: 40, y: 40 };
                                          }
                                          result = (
                                             <Rectangle
                                                key={i}
                                                id={perfilDeVisualizacao.descricao}
                                                shapeProps={{
                                                   x: coordenadaInicial.x,
                                                   y: coordenadaInicial.y,
                                                   height: coordenadaFinal.y - coordenadaInicial.y,
                                                   width: coordenadaFinal.x - coordenadaInicial.x,
                                                   fill: perfilDeVisualizacao.cor,
                                                }}
                                                isSelected={
                                                   this.state.perfilDeVisualizacaoSelecionado &&
                                                   perfilDeVisualizacao.descricao ===
                                                      this.state.perfilDeVisualizacaoSelecionado.descricao
                                                }
                                                text={
                                                   this.state.mostrarDescricao ? perfilDeVisualizacao.descricao : null
                                                }
                                                onSelect={() => {
                                                   this.setState({
                                                      perfilDeVisualizacaoSelecionado: perfilDeVisualizacao,
                                                      uidDoPerfilDeVisualizacaoSelecionado:
                                                         perfilDeVisualizacao.descricao,
                                                      uidDaAreaSelecionada: null,
                                                      areaSelecionada: null,
                                                      macDoGatewaySelecionado: null,
                                                      escalaSelecionada: null,
                                                   });
                                                }}
                                                onChange={(newAttrs) => {
                                                   let coordenadaInicial = { x: newAttrs.x, y: newAttrs.y };
                                                   let coordenadaFinal = {
                                                      x: newAttrs.x + newAttrs.width,
                                                      y: newAttrs.y + newAttrs.height,
                                                   };
                                                   let mapa = this.state.mapa;
                                                   mapa.perfisDeVisualizacao[i].coordenadas = [
                                                      coordenadaInicial,
                                                      coordenadaFinal,
                                                   ];
                                                   this.setState({ mapa: mapa });
                                                }}
                                                onClick={() => {
                                                   this.setState({
                                                      perfilDeVisualizacaoSelecionado: perfilDeVisualizacao,
                                                      uidDoPerfilDeVisualizacaoSelecionado: perfilDeVisualizacao.uid,
                                                      uidDaAreaSelecionada: null,
                                                      areaSelecionada: null,
                                                      macDoGatewaySelecionado: null,
                                                      escalaSelecionada: null,
                                                   });
                                                }}
                                             />
                                          );
                                       }
                                       return result;
                                    })}

                                 {this.state.mapa.gateways.map((gateway, i) => {
                                    return (
                                       <GatewayImage
                                          key={i}
                                          point={gateway.coordenada}
                                          onSelect={() => {
                                             this.setState({
                                                macDoGatewaySelecionado: gateway.gateway.mac,
                                                areaSelecionada: null,
                                                uidDaAreaSelecionada: null,
                                                escalaSelecionada: null,
                                             });
                                          }}
                                          isSelected={gateway.gateway.mac === this.state.macDoGatewaySelecionado}
                                          mac={gateway.gateway.mac}
                                          onDragEnd={(point) => {
                                             let mapa = this.state.mapa;
                                             mapa.gateways[i].coordenada = point;
                                             mapa.gateways[i].gateway.local = this.getLocalPorCoordenada(point);
                                             this.setState({ mapa: mapa });
                                          }}
                                          local={gateway.local}
                                          mapWidth={this.state.mapa.largura}
                                       />
                                    );
                                 })}

                                 <ScaleLine
                                    id={'escala'}
                                    shapeProps={{
                                       x: this.state.mapa.escalaX1,
                                       y: this.state.mapa.escalaY1,
                                       points: [0, 0, this.state.mapa.escalaX2, 0],
                                       stroke: 'red',
                                       strokeWidth:
                                          this.state.mapa.largura < 1000
                                             ? 5
                                             : this.state.mapa.largura < 2000
                                             ? 10
                                             : this.state.mapa.largura < 3000
                                             ? 15
                                             : this.state.mapa.largura < 4000
                                             ? 20
                                             : 30,
                                    }}
                                    isSelected={this.state.escalaSelecionada}
                                    text={LANG.telaDeConfiguracaoDeMapa.escala}
                                    onSelect={() => {
                                       this.setState({
                                          escalaSelecionada: true,
                                          uidDaAreaSelecionada: null,
                                          areaSelecionada: null,
                                          perfilDeVisualizacaoSelecionado: null,
                                          uidDoPerfilDeVisualizacaoSelecionado: null,
                                          macDoGatewaySelecionado: null,
                                       });
                                    }}
                                    onChange={(newAttrs) => {
                                       let mapa = this.state.mapa;

                                       if (newAttrs.width) {
                                          mapa.escalaX2 = newAttrs.width;
                                       }

                                       mapa.escalaX1 = newAttrs.x;
                                       mapa.escalaY1 = newAttrs.y;
                                       mapa.escalaPx = mapa.escalaX2;

                                       this.setState({ mapa: mapa });
                                    }}
                                    fixedHeight={
                                       this.state.mapa.largura < 1000
                                          ? 5
                                          : this.state.mapa.largura < 2000
                                          ? 10
                                          : this.state.mapa.largura < 3000
                                          ? 15
                                          : this.state.mapa.largura < 4000
                                          ? 20
                                          : 30
                                    }
                                 />
                              </Layer>
                           </Stage>
                        </TransformComponent>
                     </TransformWrapper>
                  )}
               </div>
            </div>
         )
      );
   };

   cadastroDeAcoes = () => {
      return (
         <div>
            <SubCadastro
               titulo={this.props.lang.telaDeConfiguracaoDeMapa.notificacoesEAcoes}
               exibirTitulos={true}
               itens={this.state.areaSelecionada.acoes}
               lang={this.props.lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.acaoSelecionada = {};
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.acaoSelecionada.categoria) {
                           showError(this.props.lang.telaDeConfiguracaoDeMapa.informeACategoria);
                           reject();
                           return;
                        }

                        if (!state.areaSelecionada.acoes) {
                           state.areaSelecionada.acoes = [];
                        }
                        state.areaSelecionada.acoes.push({
                           categoria: state.acaoSelecionada.categoria,
                           listaDeEmails: state.acaoSelecionada.listaDeEmails,
                           listaDeCelularesParaSms: state.acaoSelecionada.listaDeCelularesParaSms,
                        });
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.acaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.areaSelecionada.acoes.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [
                     { titulo: this.props.lang.telaDeConfiguracaoDeMapa.categoria, width: '25%', className: null },
                     {
                        titulo: this.props.lang.telaDeConfiguracaoDeMapa.notificarNoNavegador,
                        width: '25%',
                        className: null,
                     },
                     { titulo: this.props.lang.telaDeConfiguracaoDeMapa.emails, width: '25%', className: null },
                     { titulo: this.props.lang.telaDeConfiguracaoDeMapa.celulares, width: '25%', className: null },
                  ];
               }}
               linha={(item) => {
                  return [
                     item.categoria && item.categoria.descricao,
                     this.props.lang.sim,
                     item.listaDeEmails,
                     item.listaDeCelularesParaSms,
                  ];
               }}
               formulario={() => {
                  return (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <CheckBox
                                    label={this.props.lang.telaDeConfiguracaoDeMapa.notificarNoNavegador}
                                    defaultChecked={true}
                                    disabled={true}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{this.props.lang.telaDeConfiguracaoDeMapa.gategoria}</Label>
                                 <Select
                                    name={'local'}
                                    defaultValue={this.state.acaoSelecionada.categoria}
                                    options={[
                                       { id: 1, descricao: this.props.lang.telaDeConfiguracaoDeMapa.atencao },
                                       { id: 2, descricao: this.props.lang.telaDeConfiguracaoDeMapa.alerta },
                                    ]}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          state.acaoSelecionada.categoria = i;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        {this.state.acaoSelecionada.categoria && this.state.acaoSelecionada.categoria.id !== 3 && (
                           <Fragment>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.props.lang.telaDeConfiguracaoDeMapa.listaDeEmailsSeparadosPorPontoEVirgula}</Label>
                                       <TextInput
                                          defaultValue={this.state.acaoSelecionada.listaDeEmails}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.acaoSelecionada.listaDeEmails = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <FormGroup>
                                       <Label>{this.props.lang.telaDeConfiguracaoDeMapa.listaDeCelularesSeparadosPorPontoEVirgula}</Label>
                                       <TextInput
                                          defaultValue={this.state.acaoSelecionada.listaDeCelularesParaSms}
                                          onChange={(e) => {
                                             updateState(this, (state) => {
                                                state.acaoSelecionada.listaDeCelularesParaSms = e.target.value;
                                             });
                                          }}
                                       />
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Fragment>
                        )}
                     </Fragment>
                  );
               }}
            />
            <br />
            <Line />
            <div style={{ padding: 5 }}>
               <Button
                  text={LANG.telaDeConfiguracaoDeMapa.fechar}
                  style={{ width: 200 }}
                  onClick={() => {
                     this.setState({ mostrarAcoes: false });
                  }}
               />
            </div>
         </div>
      );
   };

   render() {
      return !this.state.mapa ? (
         <div />
      ) : (
         <div
            id='main'
            style={{
               display: 'flex',
               flexDirection: 'column',
               overflow: 'auto',
               maxHeight: '100%',
               minHeight: '100%',
               height: '100%',
               width: '100%',
               maxWidth: '100%',
            }}
         >
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '100%', minWidth: '100%' }}>
               {this.state.contrleFechado && this.controlesFechado()}
               {!this.state.contrleFechado && this.controles()}
               {this.state.mapa && this.planta()}
            </div>
         </div>
      );
   }
}

const MapImage = ({ src }) => {
   const [image] = useImage(src);
   return <Image image={image} />;
};

const GatewayImage = ({ point, mac, isSelected, onDragStart, onDragEnd, onSelect, mapWidth }) => {
   const [image] = useImage(gatewayGreen);
   const [imageX, setImageX] = useState(point && point.x ? point.x : 20);
   const [imageY, setImageY] = useState(point && point.y ? point.y : 20);

   const handleImageDragEnd = (e) => {
      setImageX(e.target.x());
      setImageY(e.target.y());
      if (onDragEnd) {
         onDragEnd({
            x: e.target.x(),
            y: e.target.y(),
         });
      }
   };

   let tamanho =
      mapWidth < 1000 ? 32 : mapWidth.width < 2000 ? 40 : mapWidth.width < 3000 ? 60 : mapWidth.width < 4000 ? 80 : 120;

   return (
      <Image
         id={mac}
         image={image}
         height={tamanho}
         width={tamanho}
         draggable={true}
         x={imageX}
         y={imageY}
         onDragEnd={handleImageDragEnd}
         onClick={onSelect}
         onTap={onSelect}
         onDragStart={onDragStart}
         onMouseDown={onSelect}
      />
   );
};

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange, text, id }) => {
   const shapeRef = React.useRef();
   const trRef = React.useRef();

   React.useEffect(() => {
      if (isSelected) {
         // we need to attach transformer manually
         trRef.current.nodes([shapeRef.current]);
         trRef.current.getLayer().batchDraw();
      }
   }, [isSelected]);

   return (
      <React.Fragment>
         <Rect
            id={id}
            onClick={onSelect}
            onTap={onSelect}
            onMouseDown={onSelect}
            ref={shapeRef}
            {...shapeProps}
            draggable
            onDragEnd={(e) => {
               onChange({
                  ...shapeProps,
                  x: e.target.x(),
                  y: e.target.y(),
               });
            }}
            onTransformEnd={(e) => {
               // transformer is changing scale of the node
               // and NOT its width or height
               // but in the store we have only width and height
               // to match the data better we will reset scale on transform end
               const node = shapeRef.current;
               const scaleX = node.scaleX();
               const scaleY = node.scaleY();

               // we will reset it back
               node.scaleX(1);
               node.scaleY(1);
               onChange({
                  ...shapeProps,
                  x: node.x(),
                  y: node.y(),
                  // set minimal value
                  width: Math.max(5, node.width() * scaleX),
                  height: Math.max(node.height() * scaleY),
               });
            }}
         />
         {isSelected && (
            <Transformer
               ref={trRef}
               name={'transformer'}
               boundBoxFunc={(oldBox, newBox) => {
                  // limit resize
                  if (newBox.width < 5 || newBox.height < 5) {
                     return oldBox;
                  }
                  return newBox;
               }}
            />
         )}
         {text && (
            <Text
               text={text}
               fontSize={
                  shapeProps.width < 1000
                     ? 15
                     : shapeProps.width < 2000
                     ? 30
                     : shapeProps.width < 3000
                     ? 15
                     : shapeProps.width < 4000
                     ? 60
                     : 75
               }
               fontStyle={'bold'}
               fill={'#333'}
               x={shapeProps.x + 5}
               y={shapeProps.y + 5}
            />
         )}
      </React.Fragment>
   );
};

const ScaleLine = ({ shapeProps, isSelected, onSelect, onChange, text, id, fixedHeight }) => {
   const shapeLineRef = React.useRef();
   const trLineRef = React.useRef();

   React.useEffect(() => {
      if (isSelected) {
         // we need to attach transformer manually
         trLineRef.current.nodes([shapeLineRef.current]);
         trLineRef.current.getLayer().batchDraw();
      }
   }, [isSelected]);

   return (
      <React.Fragment>
         <KonvaLine
            id={id}
            onClick={onSelect}
            onTap={onSelect}
            onMouseDown={onSelect}
            ref={shapeLineRef}
            {...shapeProps}
            draggable={true}
            height={fixedHeight}
            onDragEnd={(e) => {
               onChange({
                  x: e.target.x(),
                  y: e.target.y(),
                  width: e.target.width(),
                  height: e.target.height(),
               });
            }}
            onTransformEnd={(e) => {
               // transformer is changing scale of the node
               // and NOT its width or height
               // but in the store we have only width and height
               // to match the data better we will reset scale on transform end
               const node = shapeLineRef.current;
               const scaleX = node.scaleX();

               // we will reset it back
               node.scaleX(1);

               onChange({
                  x: node.x(),
                  y: node.y(),
                  width: Math.max(5, node.width() * scaleX),
                  //height: Math.max(5, node.height() * scaleY),
               });
            }}
         />
         {isSelected && (
            <Transformer
               ref={trLineRef}
               name={'transformer'}
               boundBoxFunc={(oldBox, newBox) => {
                  newBox.height = fixedHeight;
                  return newBox;
               }}
               rotateEnabled={false}
            />
         )}
      </React.Fragment>
   );
};
