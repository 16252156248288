import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showError } from '../../components/Messages';
import { LayoutParams } from '../../config/LayoutParams';
import { emptyStrIfNull, updateState } from '../../utils/Functions';
import { BaseController } from '../BaseController';

export default class EquipamentoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'EquipamentoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.numeroDeSerie,
            width: '15%',
            orderby: 'numeroDeSerie',
         },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.patrimonio,
            width: '12%',
            orderby: 'patrimonio',
         },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.nome,
            width: '20%',
            orderby: 'Nome',
         },

         {
            titulo: this.lang.telaDeCadastroDeEquipamento.tag,
            width: '15%',
            orderby: 'MacDaTag',
         },
         { titulo: this.lang.telaDeCadastroDeEquipamento.predio, width: '15%', orderby: 'NomeDoPredio' },
         { titulo: this.lang.telaDeCadastroDeEquipamento.local, width: '15%', orderby: 'NomeDoLocal' },
         {
            titulo: this.lang.telaDeCadastroDeEquipamento.situacao,
            width: '8%',
            orderby: 'situacao',
         },
      ];
   };

   getDadosDaTabela = (item) => {
      return [
         item.id,
         item.numeroDeSerie,
         item.patrimonio,
         item.nome,
         item.macDaTag ? (
            <div>
               <span>{item.macDaTag}</span>
               <span>
                  {item.percentualDeBateria ? (' - ' + emptyStrIfNull(item.percentualDeBateria ? item.percentualDeBateria + '%' : null) +
                     (item.bateria !== item.percentualDeBateria ? ' (' + item.bateria + 'v)' : '')) : ''
                  
                  }
               </span>
               <span>{item.temperatura ? ' - ' + parseInt(item.temperatura) + 'º' : null}</span>
            </div>
         ) : null,
         item.nomeDoPredio,
         item.nomeDoLocal,
         item.situacao.descricao,
      ];
   };

   getAcoesDaTabela = (item) => {
      return (
         this.view.props.mostrarHistorico && (
            <div style={{ display: '' }}>
               <FontAwesomeIcon
                  className='custom-hover'
                  style={{
                     fontSize: 23,
                     paddingTop: 2,
                     marginLeft: 3,
                     marginRight: 3,
                     color: LayoutParams.colors.corSecundaria,
                  }}
                  title={this.view.props.lang.telaDeCadastroDeEquipamento.historico}
                  cursor='pointer'
                  icon={faListAlt}
                  onClick={() => {
                     this.view.setState({
                        id: item.id,
                        nome: item.nome,
                        mostrarHistoricoDeMonitoramento: true,
                     });
                  }}
               />
            </div>
         )
      );
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeONomeDoEquipamento);
            reject();
            return;
         }

         if (!item.numeroDeSerie) {
            showError(this.lang.telaDeCadastroDeEquipamento.mensagens.informeONumeroDeSerieDoEquipamento);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            numeroDeSerie: item.numeroDeSerie,
            situacao: item.situacao ? item.situacao.id : null,
            patrimonio: item.patrimonio,
            tag: item.tag,
            notificacoesDeBateriaFraca: item.notificacoesDeBateriaFraca,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };

   antesDeEditar = () => {
      return updateState(this.view, (state) => {
         state.dataDeFiltroDeLog = new Date();
         state.filtroDeLog = null;
         state.gateway = null;
         state.formPacotes = {};
         state.formLogs = {};
         state.formAlteracaoDeLocal = {};
         state.dataDeFiltroDeAlteracaoDeLocal = new Date();
      });
   };
}
