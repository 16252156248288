import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import EmailInput from '../../components/EmailInput';
import SubCadastro from '../../components/SubCadastro';
import CheckBox from '../../components/CheckBox';
import ServicoDeNotificacaoController from '../../controllers/Empresa/ServicoDeNotificacaoController';
import { updateState } from '../../utils/Functions';
import TimeInput from '../../components/TimeInput';

export default class ServicoDeNotificacaoView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true };
      this.controller = new ServicoDeNotificacaoController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let tela = lang.telaDeCadastroDeServicoDeNotificacao;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.horaInicial}</Label>
                     <TimeInput
                        defaultValue={this.state.itemSelecionado.horaInicial}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.horaInicial = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.horaFinal}</Label>
                     <TimeInput
                        defaultValue={this.state.itemSelecionado.horaFinal}
                        onChange={(value) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.horaFinal = value;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>

            <SubCadastro
               titulo={tela.destinatarios}
               itens={this.state.itemSelecionado.configuracoes}
               lang={lang}
               novo={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = {};
                     }).then(() => resolve());
                  });
               }}
               inserir={() => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.configuracoes) {
                           state.itemSelecionado.configuracoes = [];
                        }
                        state.itemSelecionado.configuracoes.push(this.state.configuracaoSelecionada);
                     }).then(() => resolve());
                  });
               }}
               selecionarParaAlteracao={(index) => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = JSON.parse(
                           JSON.stringify(state.itemSelecionado.configuracoes[index])
                        );
                     }).then(() => resolve());
                  });
               }}
               alterar={(index) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        state.itemSelecionado.configuracoes[index].nome = this.state.configuracaoSelecionada.nome;
                        state.itemSelecionado.configuracoes[index].telefone =
                           this.state.configuracaoSelecionada.telefone;
                        state.itemSelecionado.configuracoes[index].email = this.state.configuracaoSelecionada.email;
                        state.itemSelecionado.configuracoes[index].notificarViaSms =
                           this.state.configuracaoSelecionada.notificarViaSms;
                        state.itemSelecionado.configuracoes[index].notificarViaEmail =
                           this.state.configuracaoSelecionada.notificarViaEmail;
                        state.itemSelecionado.configuracoes[index].notificarViaLigacao =
                           this.state.configuracaoSelecionada.notificarViaLigacao;
                        state.itemSelecionado.configuracoes[index].notificarViaWhatsApp =
                           this.state.configuracaoSelecionada.notificarViaWhatsApp;
                     }).then(() => resolve());
                  });
               }}
               cancelar={() => {
                  return new Promise((resolve) => {
                     updateState(this, (state) => {
                        state.configuracaoSelecionada = null;
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.configuracoes.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [
                     { titulo: tela.nome, width: '30%', className: null },
                     { titulo: tela.telefone + '/E-mail', width: '25%', className: null },
                     { titulo: 'SMS', width: '15%', className: null },
                     { titulo: 'E-mail', width: '15%', className: null },
                     { titulo: tela.ligacao, width: '15%', className: null },
                     { titulo: 'WhatsApp', width: '15%', className: null },
                  ];
               }}
               linha={(item) => {
                  return [
                     item.nome,
                     [item.telefone, item.email].join('/'),
                     item.notificarViaSms ? this.props.lang.sim : '',
                     item.notificarViaEmail ? this.props.lang.sim : '',
                     item.notificarViaLigacao ? this.props.lang.sim : '',
                     item.notificarViaWhatsApp ? this.props.lang.sim : '',
                  ];
               }}
               formulario={() => {
                  return (
                     <Fragment>
                        <Row>
                           <Col>
                              <FormGroup>
                                 <Label>{tela.nome}</Label>
                                 <TextInput
                                    defaultValue={this.state.configuracaoSelecionada.nome}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.nome = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <Label>{tela.email}</Label>
                                 <EmailInput
                                    defaultValue={this.state.configuracaoSelecionada.email}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.email = e.target.value;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                           <Col>
                              <FormGroup>
                                 <Label>{tela.telefone}</Label>
                                 <TextInput
                                    defaultValue={this.state.configuracaoSelecionada.telefone}
                                    onChange={(e) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.telefone = e.target.value;
                                       });
                                    }}
                                    upperCase={true}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                        <Row>
                           <Col>
                              <FormGroup
                                 controlId='chknotificarViaSms'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={this.state.configuracaoSelecionada.notificarViaSms ? true : false}
                                    name='notificarViaSms'
                                    label='SMS'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.notificarViaSms = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>

                              <FormGroup
                                 controlId='chknotificarViaEmail'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={this.state.configuracaoSelecionada.notificarViaEmail ? true : false}
                                    name='notificarViaEmail'
                                    label='E-mail'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.notificarViaEmail = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>
                              <FormGroup
                                 controlId='chknotificarViaLigacao'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={
                                       this.state.configuracaoSelecionada.notificarViaLigacao ? true : false
                                    }
                                    name='notificarViaLigacao'
                                    label={tela.ligacao}
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.notificarViaLigacao = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>

                              <FormGroup
                                 controlId='chknotificarViaWhatsApp'
                                 style={{
                                    margin: 'auto',
                                    paddingLeft: '5px',
                                    fontWeight: 'normal',
                                    width: '100%',
                                 }}
                              >
                                 <CheckBox
                                    defaultChecked={
                                       this.state.configuracaoSelecionada.notificarViaWhatsApp ? true : false
                                    }
                                    name='notificarViaWhatsApp'
                                    label='WhatsApp'
                                    onChange={(checked) => {
                                       updateState(this, (state) => {
                                          state.configuracaoSelecionada.notificarViaWhatsApp = checked;
                                       });
                                    }}
                                 />
                              </FormGroup>
                           </Col>
                        </Row>
                     </Fragment>
                  );
               }}
            />

            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeServicoDeNotificacao.titulo}
            url={'/servicodenotificacao'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'nome'}
            permissoes={[1481, 1481, 1481, 1481]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            form={this.props.form}
            itemVazio={{}}
         />
      );
   }
}
