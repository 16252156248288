import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { numberToString } from '../utils/Functions';
import '../contents/css/decimal-input.css';

export default class DecimalInput extends Component {
	constructor(props) {
		super(props);

      this.decimalPlaces = props.decimalPlaces !== null && props.decimalPlaces !== undefined ? props.decimalPlaces : 2;

		this.state = {
			defaultValue:
				props.defaultValue === null || props.defaultValue === undefined
					? null
					: props.defaultValue * this.getMultiplicador(this.decimalPlaces),
		};
	}

	getMultiplicador(decimalPlaces) {
		let multiplicador = parseInt('1' + ''.padEnd(decimalPlaces, '0'));
		return multiplicador;
	}

	render() {
		return (
			<NumberFormat
				className='form-control decimal-input right-align'
            decimalSeparator={
               this.props.lang && this.props.lang.decimalSeparator ? this.props.lang.decimalSeparator : ','
            }
            thousandSeparator={
               this.props.lang && this.props.lang.thousandSeparator ? this.props.lang.thousandSeparator : '.'
            }
            format={
               this.decimalPlaces === 0
                  ? null
                  : (value) => {
					let formated =
						value === null || value === undefined
							? null
							: numberToString(
									value / this.getMultiplicador(this.decimalPlaces),
									this.decimalPlaces,
                                  this.decimalPlaces,
                                  this.props.lang
							  );
					return formated;
                    }
            }
				defaultValue={this.state.defaultValue}
				onValueChange={(values) => {
					if (this.props.onChange) {
						let value =
							values.value === null || values.value === undefined
								? null
								: values.value / this.getMultiplicador(this.decimalPlaces);

                  let formattedValue = value === null || value === undefined ? null : numberToString(value, 0, 6, this.props.lang);
						this.props.onChange({
							value: value,
							floatValue: value,
							formattedValue: formattedValue,
						});
					}
				}}				
				style={this.props.style}
                name={this.props.name}
                id={this.props.id}
				key={this.props.key}
				ref={this.props.ref}
                allowNegative={this.props.allowNegative === false ? false : true}
			/>
		);
	}
}
