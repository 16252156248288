import React from 'react';
import Calendar from 'react-calendar';
import { InputGroup, Form, Modal } from 'react-bootstrap';
import IconButton from './IconButton';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import * as moment from 'moment';
import { showError } from '../components/Messages';
import { replaceAll } from '../utils/Functions';
import '../contents/css/date-input.css';
import { LayoutParams } from '../config/LayoutParams';
import SessionManager from '../models/SessionManager';
import { getLang } from '../models/Lang';

let LANG = {};

export default class DateInput extends React.Component {
   constructor(props) {
      super(props);

      let date = new Date();
      if (props.defaultValue && moment(props.defaultValue).isValid()) {
         date = moment(props.defaultValue).toDate();
      }

      let sessionManager = new SessionManager();
      LANG = getLang(sessionManager.getLang());

      this.state = { date: date, dataDigitada: moment(date).format(LANG.dateFormat), mostrarCalendario: false };
      this.setDateFromCalendar = this.setDateFromCalendar.bind(this);
   }

   setDateFromCalendar(value) {
      let date = value;
      this.setState({ date: date });
      if (!date) {
         if (this.input) {
            this.input.value = null;
         }
         this.setState({ dataDigitada: null });
      } else {
         if (this.input) {
            this.input.value = moment(date).format(LANG.dateFormat);
         }
         this.setState({ dataDigitada: moment(date).format(LANG.dateFormat) });
      }
      if (this.props.onChange) {
         this.props.onChange(date);
      }
   }

   closeCalendar() {
      setTimeout(() => {
         this.setState({ mostrarCalendario: false });
      }, 100);
   }

   render() {
      let inputBackgroundColor = {
         backgroundColor: this.props.readOnly ? '#e9ecef' : null,
         paddingLeft: 6,
         paddingRight: 6,
      };
      return (
         <DateInputStyled style={{ display: 'flex' }} name={this.props.name} id={this.props.id} key={this.props.key}>
            <InputGroup style={{ backgroundColor: 'transparent', flexWrap: 'nowrap' }}>
               <Form.Control
                  type='text'
                  className={'date-input' + (this.props.className ? ' ' + this.props.className : '')}
                  ref={(c) => (this.input = c)}
                  defaultValue={this.props.defaultValue ? moment(this.state.date).format(LANG.dateFormat) : null}
                  style={{ ...this.props.style, ...inputBackgroundColor }}
                  onChange={(e) => {
                     this.setState({ dataDigitada: e.target.value });
                  }}
                  readOnly={this.props.readOnly}
                  maxLength={10}
                  onBlur={() => {
                     if (this.state.dataDigitada) {
                        let d = null;
                        let dataString = null;
                        if (LANG.lang_id === 'pt-BR' || LANG.lang_id === 'es') {
                           dataString = replaceAll(this.state.dataDigitada, '/', '');
                           let dia = dataString.substr(0, 2);
                           let mes = dataString.substr(2, 2);
                           let ano = dataString.substr(4, 4);
                           d = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));
                        } else if (LANG.lang_id === 'en-US') {
                           dataString = replaceAll(this.state.dataDigitada, '-', '');
                           let ano = dataString.substr(0, 4);
                           let mes = dataString.substr(4, 2);
                           let dia = dataString.substr(6, 4);
                           d = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));
                        }

                        var d2 = moment(d);
                        if (d2.isValid() && dataString === d2.format(replaceAll(replaceAll(LANG.dateFormat, '-', ''), '/', ''))) {
                           /*this.setState({ date: d2.toDate(), mostrarCalendario: false }, () =>
                              this.setState({ mostrarCalendario: true })
                           );*/
                           if (this.props.onChange) {
                              this.props.onChange(d2.toDate());
                           }
                           this.input.value = d2.format(LANG.dateFormat);
                        } else {
                           showError(this.state.dataDigitada + ' ' + LANG.naoEUmaDataValida);
                           this.input.value = moment(this.state.date ? this.state.date : new Date()).format(
                              LANG.dateFormat
                           );
                        }
                     } else {
                        this.setState({ date: null, dataDigitada: null });
                        if (this.props.onChange) {
                           this.props.onChange(null);
                        }
                     }
                  }}
               />
               {!this.props.readOnly && (
                  <InputGroup.Append className='hide-when-readonly'>
                     <InputGroup.Text
                        style={{
                           padding: '1px 6px 1px 6px',
                           height: this.props.style && this.props.style.height ? this.props.style.height : 38,
                           width: 40,
                           justifyContent: 'center',
                        }}
                        onClick={() => this.setState({ mostrarCalendario: true })}
                     >
                        <div style={{ height: 24, width: 30, display: 'table-cell', paddingTop: 1 }}>
                           <IconButton
                              style={{ color: LayoutParams.colors.corSecundaria, fontSize: 20 }}
                              icon={faCalendarAlt}
                              onClick={this.novo}
                           />
                        </div>
                        {this.calendario()}
                     </InputGroup.Text>
                  </InputGroup.Append>
               )}
            </InputGroup>
         </DateInputStyled>
      );
   }

   calendario = () => {
      return (
         <React.Fragment>
            <Modal
               show={this.state.mostrarCalendario}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.closeCalendar();
               }}
               aria-labelledby='contained-modal-title-vcenter'
               centered
               size='sm'
            >
               <Modal.Body
                  style={{
                     padding: '15px 15px 15px 15px',
                     fontSize: 15,
                     width: 'fit-content',
                  }}
               >
                  {this.state.mostrarCalendario && (
                     <Calendar
                        onChange={this.setDateFromCalendar}
                        value={this.state.date}
                        onClickDay={() => {
                           this.closeCalendar();
                        }}
                        prevLabel={'<'}
                        nextLabel={'>'}
                        prev2Label={null}
                        next2Label={null}
                        showFixedNumberOfWeeks={false}
                        calendarType={'US'}
                     />
                  )}

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                     <div
                        style={{
                           textAlign: 'right',
                           color: '#999',
                           cursor: 'pointer',
                           marginRight: 10,
                           fontSize: 14,
                           display: 'flex',
                           justifyContent: 'flex-end',
                           width: 140,
                        }}
                        onClick={() => {
                           this.setDateFromCalendar(null);
                           this.closeCalendar();
                        }}
                     >
                        <div
                           style={{
                              display: 'table-cell',
                              paddingLeft: 10,
                              paddingRight: 10,
                              width: 75
                           }}
                        >
                           <span>{LANG.limpar}</span>
                        </div>
                     </div>
                     <div
                        style={{
                           color: '#999',
                           textAlign: 'right',
                           marginRight: 10,
                           display: 'flex',
                           justifyContent: 'flex-start',
                           width: '100%',
                        }}
                     >
                        <div
                           style={{
                              cursor: 'pointer',
                              marginRight: 10,
                              display: 'flex',
                           }}
                           onClick={() => {
                              this.closeCalendar();
                           }}
                        >
                           <div
                              style={{
                                 display: 'table-cell',
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              }}
                           >
                              <span>{LANG.cancelar}</span>
                           </div>
                        </div>
                     </div>
                     <div
                        style={{
                           textAlign: 'right',
                           color: '#999',
                           cursor: 'pointer',
                           marginRight: 10,
                           fontSize: 14,
                           display: 'flex',
                           justifyContent: 'flex-end',
                           minWidth: 55,
                        }}
                        onClick={() => {
                           this.setDateFromCalendar(new Date());
                           this.closeCalendar();
                        }}
                     >
                        <div
                           style={{
                              display: 'table-cell',
                              paddingLeft: 10,
                              paddingRight: 10,
                           }}
                        >
                           <span>{LANG.hoje}</span>
                        </div>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </React.Fragment>
      );
   };
}

const DateInputStyled = styled.div`
   .dropdown-toggle {
      box-shadow: none;
   }
`;
