import { dateTimeToString, formatDate } from '../utils/Functions';
import { BaseController } from './BaseController';

export default class AuditoriaController extends BaseController {
   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: 'Usuário', width: '25%', orderby: 'nomeDoUsuario' },
         { titulo: 'Ação', width: '25%', orderby: 'acao' },
         { titulo: 'Data', width: '25%', orderby: 'data' },
         { titulo: 'Detalhes', width: '25%', orderby: 'descricao' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nomeDoUsuario, item.acao.descricao, dateTimeToString(item.data, this.lang), item.descricao];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         resolve({});
      });
   };

   getFiltro = () => {
      let result = {};

      result.usuario = this.view.state.usuario  ? 
         this.view.state.usuario.idDoUsuario ?  this.view.state.usuario.idDoUsuario :
         this.view.state.usuario.id : null;
      result.acao = this.view.state.acao ? this.view.state.acao.id : null;
      result.dataInicial = formatDate(this.view.state.dataInicial);
      result.horaInicial = this.view.state.horaInicial;
      result.dataFinal = formatDate(this.view.state.dataFinal);
      result.horaFinal = this.view.state.horaFinal;

      return result;
   };
}
