import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Form, Card, ListGroup } from 'react-bootstrap';
import { LayoutParams } from '../../config/LayoutParams';
import Label from '../../components/Label';
import Button from '../../components/Button';
import TextInput from '../../components/TextInput';
import CnpjInput from '../../components/CnpjInput';
import PasswordInput from '../../components/PasswordInput';
import Select from '../../components/Select';
import CheckBox from '../../components/CheckBox';
import { Filler } from '../../components/Filler';
import { LogoLogin } from '../../components/LogoLogin';
import './../../contents/css/login-logo.css';
import { Link } from 'react-router-dom';
import LoginController from '../../controllers/User/LoginController';
import { updateState } from '../../utils/Functions';
import { showError } from '../../components/Messages';
import Recaptcha from 'react-recaptcha';
// import logoFlexmundi from './../../contents/img/logo-flexmundi.png';
import logoEClin from './../../contents/img/logo-eclin.jpeg';

let LANG = {};

export default class LoginNovoView extends Component {
   constructor(props) {
      super(props);

      let nomeDeUsuario = null;
      let tipoDeIdentificacaoComplementar = null;
      let identificacaoComplementar = null;
      let senha = null;

      let fornecedor = this.props.tipoDeAcesso === 'EMPRESA' ? localStorage.getItem('fornecedor') : null;

      let lembrarLogin = localStorage.getItem('lembrarLogin') === 'true' ? true : false;
      nomeDeUsuario = nomeDeUsuario
         ? nomeDeUsuario
         : this.props.tipoDeAcesso === 'EMPRESA'
         ? localStorage.getItem('nomeDeUsuario')
         : localStorage.getItem('nomeDeUsuarioTecnometrics');
      /*tipoDeIdentificacaoComplementar =
         this.props.tipoDeAcesso === 'EMPRESA'
            ? tipoDeIdentificacaoComplementar
               ? tipoDeIdentificacaoComplementar
               : JSON.parse(localStorage.getItem('tipoDeIdentificacaoComplementar'))
            : null;*/
      identificacaoComplementar =
         this.props.tipoDeAcesso === 'EMPRESA'
            ? identificacaoComplementar
               ? identificacaoComplementar
               : localStorage.getItem('identificacaoComplementar')
            : null;

      nomeDeUsuario = nomeDeUsuario === 'null' ? null : nomeDeUsuario;
      tipoDeIdentificacaoComplementar =
         tipoDeIdentificacaoComplementar === 'null' ? null : tipoDeIdentificacaoComplementar;
      identificacaoComplementar = identificacaoComplementar === 'null' ? null : identificacaoComplementar;
      fornecedor = this.props.tipoDeAcesso === 'EMPRESA' ? (fornecedor === 'null' ? null : fornecedor) : null;
      let isFornecedor = false;
      if (fornecedor === '18.378.925/0001-36') {
         isFornecedor = true;
      } else {
         identificacaoComplementar = fornecedor;
      }

      if (process.env.NODE_ENV === 'development') {
         if (this.props.tipoDeAcesso === 'EMPRESA') {
            tipoDeIdentificacaoComplementar = process.env.REACT_APP_CNPJ;
            nomeDeUsuario = process.env.REACT_APP_LOGIN;
            senha = process.env.REACT_APP_SENHA;
            if (senha)
            {
               senha = senha.trim();
            }
         }
      }

      this.state = {
         lembrarLogin: lembrarLogin,
         nomeDeUsuario: nomeDeUsuario,
         tipoDeIdentificacaoComplementar: tipoDeIdentificacaoComplementar,
         identificacaoComplementar: identificacaoComplementar,
         senha: senha,
         recaptcha: null,
         mostrarRecaptcha: false,
         fornecedor: fornecedor,
         isFornecedor: isFornecedor,
         selecionarEmpresaParaAcessoSupervisor: false,
      };
      this.api = props.api;
      LANG = props.lang;
      this.lang = LANG;
      this.sessionManager = props.sessionManager;
      this.controller = new LoginController(this);
   }

   componentDidMount() {
      setTimeout(() => {
         this.setState({
            mostrarRecaptcha: true,
         });
      }, 1000);
      window.onhashchange = function () {
         if (window.location.hash !== '#/' && window.location.hash !== '#/recoverpassword/empresa') {
            window.location.reload();
         }
      };
   }

   getDescricaoDoTipoDeAcesso = () => {
      let result = null;
      if (this.props.tipoDeAcesso === 'TECNOMETRICS') {
         result = LANG.tipoDeAcesso.tecnometrics;
      } else if (this.props.tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR') {
         result = LANG.tipoDeAcesso.tecnometricsSupervisor;
      } else if (this.props.tipoDeAcesso === 'EMPRESA') {
         result = LANG.tipoDeAcesso.empresa;
      }
      return result;
   };

   render() {
      return (
         <Container
            fluid
            id={'container'}
            style={{
               fontSize: 15,
               height: '100%',
               display: 'flex',
               position: 'fixed',
               backgroundColor: LayoutParams.colors.corDoTemaPrincipal,
               overflowX: 'auto',
               flexDirection: 'column',
            }}
         >
            <Row className='justify-content-md-center'>
               <Col
                  xs
                  lg='3'
                  style={{
                     minWidth: 330,
                     maxWidth: 330,
                     borderRadius: 10,
                     color: 'white',
                     paddingTop: 60,
                     marginLeft: 'auto',
                     marginRight: 'auto',
                  }}
               >
                  <br />
                  <LogoLogin />
                  {this.props.tipoDeAcesso === 'EMPRESA' && this.formLoginEmpresa()}
                  {this.props.tipoDeAcesso === 'TECNOMETRICS' && this.formLoginTecnometrics()}
                  {this.props.tipoDeAcesso === 'TECNOMETRICS-SUPERVISOR' && this.formLoginTecnometricsSupervisor()}
                  <br />
                  <br />
               </Col>
            </Row>
         </Container>
      );
   }

   formLoginTecnometrics = () => {
      let result = (
         <Fragment>
            <div
               style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: -30,
                  fontStyle: 'italic',
               }}
            >
               <span>{LANG.telaDeLogin.tipoDeAcesso}:&nbsp;</span>
               <span>{this.getDescricaoDoTipoDeAcesso()}</span>
               <br />
               <Filler height={6} />
            </div>
            <Form
               onSubmit={(event) => {
                  event.preventDefault();
                  if (this.state.lembrarLogin) {
                     localStorage.setItem('lembrarLogin', this.state.lembrarLogin);
                     localStorage.setItem('nomeDeUsuarioTecnometrics', this.state.nomeDeUsuario);
                  } else {
                     localStorage.setItem('lembrarLogin', null);
                     localStorage.setItem('nomeDeUsuarioTecnometrics', null);
                  }
                  this.controller.efetuarLogin().catch((e) => {
                     if (e) {
                        showError(e.toString());
                     }
                  });
               }}
               action='#'
               id='formLogin'
               className='justify-content-md-center'
            >
               <React.Fragment>
                  <Form.Group>
                     <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                           <Label>{LANG.telaDeLogin.nomeDeUsuario}</Label>
                        </div>
                        <div
                           style={{
                              display: 'flex',
                              minWidth: 'fit-content',
                           }}
                        >
                           <CheckBox
                              className='fs-12'
                              label={LANG.telaDeLogin.lembrarNomeDeUsuario}
                              defaultChecked={this.state.lembrarLogin}
                              onChange={(checked) => updateState(this, (state) => (state.lembrarLogin = checked))}
                           />
                        </div>
                     </div>

                     <TextInput
                        defaultValue={this.state.nomeDeUsuario}
                        name={'nomeDeUsuario'}
                        onChange={(e) => updateState(this, (state) => (state.nomeDeUsuario = e.target.value))}
                     />
                  </Form.Group>
                  <Form.Group>
                     <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                           <Label>{LANG.telaDeLogin.senha}</Label>
                        </div>
                        <div
                           style={{
                              display: 'flex',
                              color: LayoutParams.colors.corSecundaria,
                           }}
                        >
                           <Link to={'/recoverpassword/' + this.props.tipoDeAcesso.toLowerCase()} tabIndex={-1}>
                              <Label style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                                 <span>{LANG.telaDeLogin.esqueceuASenha}</span>
                              </Label>
                           </Link>
                        </div>
                     </div>
                     <PasswordInput
                        name={'senha'}
                        type='password'
                        defaultValue={this.state.senha}
                        onChange={(e) => updateState(this, (state) => (state.senha = e.target.value))}
                     />
                  </Form.Group>
                  <div style={{ minHeight: 78, maxWidth: 304 }}>
                     {this.state.mostrarRecaptcha && (
                        <Recaptcha
                           sitekey='6LclosMgAAAAAMpNQ7pbzKmyl2v_2UGY1exIzHvH'
                           render='onload'
                           ref={(e) => (this.recaptchaInstance = e)}
                           verifyCallback={(response) => {
                              this.setState({ recaptcha: response });
                           }}
                           onloadCallback={() => {}}
                           expiredCallback={() => {
                              this.setState({ recaptcha: null });
                           }}
                           className={'div-recaptcha'}
                           hl={LANG.lang_id}
                        />
                     )}
                  </div>
                  <br />
                  <Button
                     submit={true}
                     text={LANG.telaDeLogin.entrar}
                     inProgressText={LANG.telaDeLogin.entrando}
                     disabled={this.state.recaptcha ? false : true}
                     style={{ cursor: this.state.recaptcha ? 'pointer' : 'not-allowed' }}
                  />
               </React.Fragment>
            </Form>
         </Fragment>
      );
      return result;
   };

   formLoginEmpresa = () => {
      let result = (
         <Fragment>
            {!this.state.fornecedor && (
               <Form
                  onSubmit={(event) => {
                     event.preventDefault();
                     if (!this.state.identificacaoDoFornecedor) {
                        showError(LANG.telaDeLogin.mensagens.informeAEmpresa);
                        return;
                     }
                     let isFornecedor = false;
                     if (this.state.identificacaoDoFornecedor === '18.378.925/0001-36') {
                        isFornecedor = true;
                     }

                     let ehEmpresaVinculdaAFornecedor = this.state.identificacaoDoFornecedor === '60.747.318/0001-62';
                     if (ehEmpresaVinculdaAFornecedor) {
                        showError(LANG.telaDeLogin.mensagens.cnpjInvalido);
                        return;
                     }

                     localStorage.setItem('fornecedor', this.state.identificacaoDoFornecedor);
                     localStorage.setItem('lembrarLogin', null);
                     localStorage.setItem('nomeDeUsuario', null);
                     localStorage.setItem('tipoDeIdentificacaoComplementar', null);
                     localStorage.setItem('identificacaoComplementar', null);

                     updateState(this, (state) => {
                        state.fornecedor = this.state.identificacaoDoFornecedor;
                        state.isFornecedor = isFornecedor;
                        if (!isFornecedor) {
                           state.identificacaoComplementar = state.fornecedor;
                        }
                     });
                  }}
                  action='#'
               >
                  <Form.Group>
                     <Label>{LANG.telaDeLogin.cnpj}</Label>
                     <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <CnpjInput
                           defaultValue={this.state.identificacaoDoFornecedor}
                           name={'identificacaoDoFornecedor'}
                           onChange={(e) => {
                              updateState(this, (state) => (state.identificacaoDoFornecedor = e.formattedValue));
                           }}
                        />
                     </div>
                     <br />
                     <div>
                        <Button submit={true} text={LANG.telaDeLogin.proximo} />
                     </div>
                  </Form.Group>
               </Form>
            )}

            {this.state.fornecedor && (
               <Form
                  onSubmit={(event) => {
                     event.preventDefault();
                     if (this.state.lembrarLogin) {
                        localStorage.setItem('lembrarLogin', this.state.lembrarLogin);
                        localStorage.setItem('nomeDeUsuario', this.state.nomeDeUsuario);
                        localStorage.setItem(
                           'tipoDeIdentificacaoComplementar',
                           JSON.stringify(this.state.tipoDeIdentificacaoComplementar)
                        );
                        localStorage.setItem('identificacaoComplementar', this.state.identificacaoComplementar);
                     } else {
                        localStorage.setItem('lembrarLogin', null);
                        localStorage.setItem('nomeDeUsuario', null);
                        if (this.state.isFornecedor) {
                           localStorage.setItem('tipoDeIdentificacaoComplementar', null);
                           localStorage.setItem('identificacaoComplementar', null);
                        }
                     }
                     this.controller.efetuarLogin().catch((e) => {
                        if (e) {
                           showError(e.toString());
                        }
                     });
                  }}
                  action='#'
                  id='formLogin'
                  className='justify-content-md-center'
               >
                  <React.Fragment>
                     {this.props.tipoDeAcesso === 'EMPRESA' && (
                        <Fragment>
                           {this.state.fornecedor === '18.378.925/0001-36' && (
                              <Form.Group>
                                 <Card>
                                    <Card.Body style={{ padding: 5 }}>
                                       <div
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'row',
                                             // marginTop: -20,
                                             // marginBottom: -20,
                                          }}
                                       >
                                          <img alt='' src={logoEClin} />
                                       </div>
                                    </Card.Body>
                                 </Card>
                              </Form.Group>
                           )}

                           {this.state.isFornecedor && (
                              <Form.Group>
                                 <Label>{LANG.telaDeLogin.identificacaoDaEmpresa}</Label>

                                 <Select
                                    defaultValue={
                                       this.state.identificacaoComplementar
                                          ? this.state.identificacaoComplementar.id
                                          : null
                                    }
                                    options={[{ id: '60.747.318/0001-62', descricao: 'IAMSPE' }]}
                                    getKeyValue={(i) => i.id}
                                    getDescription={(i) => i.descricao}
                                    onSelect={(i) => {
                                       updateState(this, (state) => {
                                          if (i) {
                                             state.identificacaoComplementar = i.id;
                                          } else {
                                             state.identificacaoComplementar = null;
                                          }
                                       });
                                    }}
                                    nullText={''}
                                    asws={true}
                                 />
                              </Form.Group>
                           )}
                        </Fragment>
                     )}

                     <Form.Group>
                        <div style={{ display: 'flex' }}>
                           <div style={{ display: 'flex', width: '100%' }}>
                              <Label>{LANG.telaDeLogin.nomeDeUsuario}</Label>
                           </div>
                           <div
                              style={{
                                 display: 'flex',
                                 minWidth: 'fit-content',
                              }}
                           >
                              <CheckBox
                                 className='fs-12'
                                 label={LANG.telaDeLogin.lembrarNomeDeUsuario}
                                 defaultChecked={this.state.lembrarLogin}
                                 onChange={(checked) => updateState(this, (state) => (state.lembrarLogin = checked))}
                              />
                           </div>
                        </div>

                        <TextInput
                           defaultValue={this.state.nomeDeUsuario}
                           name={'nomeDeUsuario'}
                           onChange={(e) => updateState(this, (state) => (state.nomeDeUsuario = e.target.value))}
                        />
                     </Form.Group>
                     <Form.Group>
                        <div style={{ display: 'flex' }}>
                           <div style={{ display: 'flex', width: '100%' }}>
                              <Label>{LANG.telaDeLogin.senha}</Label>
                           </div>
                           <div
                              style={{
                                 display: 'flex',
                                 color: LayoutParams.colors.corSecundaria,
                              }}
                           >
                              <Link to={'/recoverpassword/' + this.props.tipoDeAcesso.toLowerCase()} tabIndex={-1}>
                                 <Label style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                                    <span>{LANG.telaDeLogin.esqueceuASenha}</span>
                                 </Label>
                              </Link>
                           </div>
                        </div>
                        <PasswordInput
                           name={'senha'}
                           type='password'
                           defaultValue={this.state.senha}
                           onChange={(e) => updateState(this, (state) => (state.senha = e.target.value))}
                        />
                     </Form.Group>
                     <div style={{ minHeight: 78 }}>
                        {this.state.mostrarRecaptcha && (
                           <Recaptcha
                              sitekey='6LclosMgAAAAAMpNQ7pbzKmyl2v_2UGY1exIzHvH'
                              render='onload'
                              ref={(e) => (this.recaptchaInstance = e)}
                              verifyCallback={(response) => {
                                 this.setState({ recaptcha: response });
                              }}
                              onloadCallback={() => {}}
                              expiredCallback={() => {
                                 this.setState({ recaptcha: null });
                              }}
                              className={'div-recaptcha'}
                              hl={LANG.lang_id}
                           />
                        )}
                     </div>
                     <br />
                     <Button
                        submit={true}
                        text={LANG.telaDeLogin.entrar}
                        inProgressText={LANG.telaDeLogin.entrando}
                        disabled={this.state.recaptcha ? false : true}
                        style={{ cursor: this.state.recaptcha ? 'pointer' : 'not-allowed' }}
                     />
                     <Filler height={6} />
                     <Button
                        submit={true}
                        text={LANG.telaDeLogin.voltar}
                        onClick={() => {
                           updateState(this, (state) => {
                              state.fornecedor = null;
                              state.tipoDeAcesso = null;
                              state.identificacaoComplementar = null;
                              state.nomeDeUsuario = null;
                              state.senha = null;
                           });
                           localStorage.setItem('fornecedor', null);
                        }}
                     />
                  </React.Fragment>
               </Form>
            )}
         </Fragment>
      );
      return result;
   };

   formLoginTecnometricsSupervisor = () => {
      let result = (
         <Fragment>
            <div
               style={{
                  fontSize: 16,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: -30,
                  fontStyle: 'italic',
               }}
            >
               <span>{LANG.telaDeLogin.tipoDeAcesso}:&nbsp;</span>
               <span>{this.getDescricaoDoTipoDeAcesso()}</span>
               <br />
               <Filler height={6} />
            </div>
            {!this.state.selecionarEmpresaParaAcessoSupervisor && (
               <Form
                  onSubmit={(event) => {
                     event.preventDefault();
                     if (this.state.lembrarLogin) {
                        localStorage.setItem('lembrarLogin', this.state.lembrarLogin);
                        localStorage.setItem('nomeDeUsuarioTecnometrics', this.state.nomeDeUsuario);
                     } else {
                        localStorage.setItem('lembrarLogin', null);
                        localStorage.setItem('nomeDeUsuarioTecnometrics', null);
                     }
                     this.controller.efetuarLogin().catch((e) => {
                        if (e) {
                           showError(e.toString());
                        }
                     });
                  }}
                  action='#'
                  id='formLogin'
                  className='justify-content-md-center'
               >
                  <React.Fragment>
                     <Form.Group>
                        <div style={{ display: 'flex' }}>
                           <div style={{ display: 'flex', width: '100%' }}>
                              <Label>{LANG.telaDeLogin.nomeDeUsuario}</Label>
                           </div>
                           <div
                              style={{
                                 display: 'flex',
                                 minWidth: 'fit-content',
                              }}
                           >
                              <CheckBox
                                 className='fs-12'
                                 label={LANG.telaDeLogin.lembrarNomeDeUsuario}
                                 defaultChecked={this.state.lembrarLogin}
                                 onChange={(checked) => updateState(this, (state) => (state.lembrarLogin = checked))}
                              />
                           </div>
                        </div>

                        <TextInput
                           defaultValue={this.state.nomeDeUsuario}
                           name={'nomeDeUsuario'}
                           onChange={(e) => updateState(this, (state) => (state.nomeDeUsuario = e.target.value))}
                        />
                     </Form.Group>
                     <Form.Group>
                        <div style={{ display: 'flex' }}>
                           <div style={{ display: 'flex', width: '100%' }}>
                              <Label>{LANG.telaDeLogin.senha}</Label>
                           </div>
                           <div
                              style={{
                                 display: 'flex',
                                 color: LayoutParams.colors.corSecundaria,
                              }}
                           >
                              <Link to={'/recoverpassword/' + this.props.tipoDeAcesso.toLowerCase()} tabIndex={-1}>
                                 <Label style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                                    <span>{LANG.telaDeLogin.esqueceuASenha}</span>
                                 </Label>
                              </Link>
                           </div>
                        </div>
                        <PasswordInput
                           name={'senha'}
                           type='password'
                           defaultValue={this.state.senha}
                           onChange={(e) => updateState(this, (state) => (state.senha = e.target.value))}
                        />
                     </Form.Group>
                     <div style={{ minHeight: 78, maxWidth: 304 }}>
                        {this.state.mostrarRecaptcha && (
                           <Recaptcha
                              sitekey='6LclosMgAAAAAMpNQ7pbzKmyl2v_2UGY1exIzHvH'
                              render='onload'
                              ref={(e) => (this.recaptchaInstance = e)}
                              verifyCallback={(response) => {
                                 this.setState({ recaptcha: response });
                              }}
                              onloadCallback={() => {}}
                              expiredCallback={() => {
                                 this.setState({ recaptcha: null });
                              }}
                              className={'div-recaptcha'}
                              hl={LANG.lang_id}
                           />
                        )}
                     </div>
                     <br />
                     <Button
                        submit={true}
                        text={LANG.telaDeLogin.entrar}
                        inProgressText={LANG.telaDeLogin.entrando}
                        disabled={this.state.recaptcha ? false : true}
                        style={{ cursor: this.state.recaptcha ? 'pointer' : 'not-allowed' }}
                     />
                  </React.Fragment>
               </Form>
            )}
            {this.state.selecionarEmpresaParaAcessoSupervisor && (
               <div className='justify-content-md-center'>
                  <React.Fragment>
                     <Form.Group>
                        <ListGroup>
                           {this.state.empresas.map((empresa, index) => {
                              return (
                                 <ListGroup.Item
                                    style={{ cursor: 'pointer' }}
                                    key={index}
                                    onClick={() => {
                                       this.controller.definirEmpresaParaAcessoSupervisor(empresa);
                                    }}
                                 >
                                    <div style={{ display: 'flex', color: '#999' }}>
                                       {/* <div style={{display: 'table-cell' , width: 60}}>
                                          <Image imagem={empresa.logo} lang={this.props.lang} width={60}/>
                                       </div> */}
                                       <div style={{ display: 'table-cell' }}>
                                          <span>{empresa.nomeFantasia}</span>
                                       </div>
                                    </div>
                                 </ListGroup.Item>
                              );
                           })}
                        </ListGroup>
                     </Form.Group>
                  </React.Fragment>
               </div>
            )}
         </Fragment>
      );
      return result;
   };
}
