import { Row, Col, FormGroup, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import React, { Component, Fragment } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import EquipamentoController from '../../controllers/Empresa/EquipamentoController';
import {
   buildQueryString,
   dateTimeToString,
   formatDate,
   isNumeric,
   numberToString2,
   prepareSearchable,
   updateState,
} from '../../utils/Functions';
import Select from '../../components/Select';
import TagView from './TagView';
import HistoricoDeMonitoramentoView from './HistoricoDeMonitoramentoView';
import { Redirect } from 'react-router-dom';
import ListaPadrao from '../ListaPadrao';
import { showError } from '../../components/Messages';
import GatewayView from './GatewayView';
import ServicoDeNotificacaoView from './ServicoDeNotificacaoView';
import { LayoutParams } from '../../config/LayoutParams';
import { Filler } from '../../components/Filler';
import DateInput from '../../components/DateInput';
import styled from 'styled-components';
import CheckBox from '../../components/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SubCadastro from '../../components/SubCadastro';

export default class EquipamentoView extends Component {
   constructor(props) {
      super(props);
      this.state = {
         mostrarAcessos: true,
         abaSelecionada: 'pacotes',
         filtroDeSituacao: 1,
      };
      this.controller = new EquipamentoController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let tela = lang.telaDeCadastroDeEquipamento;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2} xl={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.numeroDeSerie}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.numeroDeSerie}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.numeroDeSerie = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.patrimonio}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.patrimonio}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.patrimonio = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.situacao}</Label>
                     <Select
                        as='select'
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.situacaoDeEquipamento.ativo },
                           { id: 2, descricao: lang.situacaoDeEquipamento.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <Row>
               <Col>
                  <FormGroup>
                     <Label>{tela.nome}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.nome}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.nome = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>

               <Col sm={12} md={12} lg={4} xl={4}>
                  <Label>{tela.tag}</Label>
                  <Select
                     name={'tag'}
                     defaultValue={this.state.itemSelecionado.tag}
                     getKeyValue={(i) => i.id}
                     getDescription={(i) => i.mac}
                     onSelect={(i) => {
                        updateState(this, (state) => {
                           state.itemSelecionado.tag = i;
                        });
                     }}
                     formularioPadrao={(select) => {
                        return <TagView api={this.props.api} select={select} lang={this.props.lang} />;
                     }}
                     noDropDown={true}
                     readOnlyColor={'#ffff'}
                     getFilterUrl={(text) =>
                        '/tag' + buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                     }
                  />
               </Col>
            </Row>

            <br />

            <SubCadastro
               titulo={tela.notificacoesDeBateriaFraca}
               exibirTitulos={false}
               itens={this.state.itemSelecionado.notificacoesDeBateriaFraca}
               lang={lang}
               formularioPadrao={(form) => {
                  return <ServicoDeNotificacaoView api={this.props.api} form={form} lang={this.props.lang} />;
               }}
               aoSelecionar={(item) => {
                  return new Promise((resolve, reject) => {
                     updateState(this, (state) => {
                        if (!state.itemSelecionado.notificacoesDeBateriaFraca) {
                           state.itemSelecionado.notificacoesDeBateriaFraca = [];
                        }
                        state.itemSelecionado.notificacoesDeBateriaFraca.push(item);
                     }).then(() => resolve());
                  });
               }}
               excluir={(index) => {
                  updateState(this, (state) => {
                     state.itemSelecionado.notificacoesDeBateriaFraca.splice(index, 1);
                  });
               }}
               colunas={() => {
                  return [{ titulo: '', width: '100%', className: null }];
               }}
               linha={(item) => {
                  return [item.nome];
               }}
            />

            <br />

            {this.state.itemSelecionado && this.state.itemSelecionado.id && (
               <div style={{ border: '1px solid rgba(0,0,0,.125)', borderRadius: '.25rem' }}>
                  <div style={{ padding: 10 }}>
                     <Row>
                        <Col sm={3} md={3} lg={3}>
                           <strong style={{ padding: 3 }}>
                              {this.props.lang.telaDeCadastroDeEquipamento.monitoramento}
                           </strong>
                        </Col>
                     </Row>
                     <Filler height={10} />
                     <Row>
                        <Col sm={4} md={4} lg={4}>
                           <FormGroup>
                              <Label>{this.props.lang.telaDeCadastroDeEquipamento.dataDaUltimaLeitura}</Label>
                              <TextInput
                                 defaultValue={dateTimeToString(
                                    this.state.itemSelecionado.dataDaUltimaAtualizacao,
                                    this.props.lang
                                 )}
                                 readOnly={true}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </div>

                  <StyledNav style={{ padding: 0 }}>
                     <Tabs variant='tabs' defaultActiveKey={this.state.abaSelecionada} style={{ color: '#444' }}>
                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'pacotes' })}
                           eventKey='pacotes'
                           title={this.props.lang.telaDeCadastroDeEquipamento.pacotes}
                        >
                           {this.pacotes()}
                        </Tab>
                        {/* <Tab onClick={() => this.setState({ abaSelecionada: 'logs' })} eventKey='logs' title={'Logs'}>
                           {this.logs()}
                        </Tab> */}
                        <Tab
                           onClick={() => this.setState({ abaSelecionada: 'alteracaoDeLocal' })}
                           eventKey='alteracaoDeLocal'
                           title={this.props.lang.telaDeCadastroDeEquipamento.alteracaoDeLocal}
                        >
                           {this.alteracaoDeLocal()}
                        </Tab>
                     </Tabs>
                  </StyledNav>
               </div>
            )}

            <br />
         </React.Fragment>
      );
   };

   modalHistoricoMonitoramento = () => {
      return (
         this.state.mostrarHistoricoDeMonitoramento && (
            <Modal
               show={this.state.mostrarHistoricoDeMonitoramento}
               scrollable={true}
               size={'lg'}
               onHide={() => {}}
               onKeyDown={(e) => {
                  if (e.keyCode === 27) this.setState({ mostrarHistoricoDeMonitoramento: false });
               }}
               dialogClassName='h-100'
            >
               <Modal.Body
                  style={{
                     overflow: 'hidden',
                     display: 'flex',
                     position: 'relative',
                     fontSize: 13,
                     padding: '0 0 0 0',
                     maxHeight: '100%',
                  }}
               >
                  <HistoricoDeMonitoramentoView
                     lang={this.props.lang}
                     api={this.props.api}
                     idDoEquipamento={this.state.id}
                     titulo={this.state.nome}
                     aoFechar={() => {
                        this.setState({ mostrarHistoricoDeMonitoramento: false });
                     }}
                  />
               </Modal.Body>
            </Modal>
         )
      );
   };

   pacotes = () => {
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/equipamento/pacotes'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            getTitulosDaTabela={() => {
               return [
                  {},
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.data, width: '30%', orderby: 'DataDaLeitura' },
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.gateway, width: '35%', orderby: 'Gateway' },
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.distancia, width: '35%', orderby: 'Distancia' },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  null,
                  dateTimeToString(item.data, this.props.lang),
                  <div>
                     {item.gateway && (
                        <div>
                           <span>{item.gateway.mac}</span>
                           {item.gateway.patrimonio && <span>{' [' + item.gateway.patrimonio + ']'}</span>}
                        </div>
                     )}
                  </div>,
                  <div>
                     <span>{numberToString2(item.distancia)}m</span>
                  </div>,
               ];
            }}
            getFormState={() => this.state.formPacotes}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formPacotes = { ...state.formPacotes, ...formState };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            estruturaPadrao={true}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  let mac = null;

                  if (!this.state.itemSelecionado.tag) {
                     showError(this.props.lang.telaDeCadastroDeEquipamento.mensagens.esseEquipamentoNaoTemTagAssociada);
                     reject();
                     return;
                  } else {
                     mac = this.state.itemSelecionado.tag.mac;
                  }

                  resolve({
                     gateway: this.state.gateway ? this.state.gateway.mac : null,
                     mac: mac,
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{}}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{'Gateway'}</Label>
                              <Select
                                 name={'gateway'}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.mac + (i.patrimonio ? ' [' + i.patrimonio + ']' : '')}
                                 onSelect={(i) => {
                                    this.setState({ gateway: i });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <GatewayView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/gateway' +
                                    buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                 }
                              />
                           </Form.Group>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   logs = () => {
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/equipamento/logs'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id desc'}
            getTitulosDaTabela={() => {
               return [
                  { titulo: this.props.lang.codigo, orderby: 'Id', className: 'codigo' },
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.data, width: '15%' },
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.log, width: '85%' },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [item.id, dateTimeToString(item.date), item.message];
            }}
            getFormState={() => this.state.formLogs}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formLogs = { ...state.formLogs, ...formState };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            estruturaPadrao={true}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  let mac = null;

                  if (!this.state.itemSelecionado.tag) {
                     showError(this.props.lang.telaDeCadastroDeEquipamento.mensagens.esseEquipamentoNaoTemTagAssociada);
                     reject();
                     return;
                  } else {
                     mac = this.state.itemSelecionado.tag.mac;
                  }

                  if (!this.state.dataDeFiltroDeLog) {
                     showError(this.props.lang.telaDeCadastroDeEquipamento.mensagens.informeAData);
                     reject();
                     return;
                  }

                  resolve({
                     searchable: this.state.filtroDeLog,
                     tag: mac,
                     dataInicial: formatDate(this.state.dataDeFiltroDeLog),
                     dataFinal: formatDate(this.state.dataDeFiltroDeLog),
                     action: '/receiver/moko',
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{}}>
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.props.lang.telaDeCadastroDeEquipamento.data}</Label>
                              <DateInput
                                 defaultValue={this.state.dataDeFiltroDeLog}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataDeFiltroDeLog = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <FormGroup>
                              <Label>{this.props.lang.telaDeCadastroDeEquipamento.filtro}</Label>
                              <TextInput
                                 onChange={(e) => {
                                    updateState(this, (state) => {
                                       state.filtroDeLog = e.target.value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                     </Row>
                  </div>
               );
            }}
         />
      );
   };

   alteracaoDeLocal = () => {
      return (
         <ListaPadrao
            esconderTitulo={true}
            esconderBotaoFechar={true}
            iniciarVazio={true}
            url={'/equipamento/alteracaodelocal'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'id'}
            getTitulosDaTabela={() => {
               return [
                  {},
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.data, width: '30%', orderby: 'Data' },
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.gateway, width: '35%', orderby: 'Gateway' },
                  { titulo: this.props.lang.telaDeCadastroDeEquipamento.distancia, width: '35%', orderby: 'Distancia' },
               ];
            }}
            getDadosDaTabela={(item) => {
               return [
                  null,
                  dateTimeToString(item.data, this.props.lang),
                  <div>
                     {item.gateway && (
                        <div>
                           <span>{item.gateway.mac}</span>
                           {item.gateway.patrimonio && <span>{' [' + item.gateway.patrimonio + ']'}</span>}
                        </div>
                     )}
                  </div>,
                  <div>
                     <span>{numberToString2(item.distancia)}m</span>
                  </div>,
               ];
            }}
            getFormState={() => this.state.formAlteracaoDeLocal}
            setFormState={(formState, callback) => {
               return new Promise((resolve) => {
                  updateState(this, (state) => {
                     state.formAlteracaoDeLocal = { ...state.formAlteracaoDeLocal, ...formState };
                  }).then(() => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            estruturaPadrao={true}
            getFiltro={() => {
               return new Promise((resolve, reject) => {
                  let mac = null;

                  if (!this.state.itemSelecionado.tag) {
                     showError(this.props.lang.telaDeCadastroDeEquipamento.mensagens.esseEquipamentoNaoTemTagAssociada);
                     reject();
                     return;
                  } else {
                     mac = this.state.itemSelecionado.tag.mac;
                  }

                  resolve({
                     dataInicial: formatDate(this.state.dataDeFiltroDeAlteracaoDeLocal),
                     dataFinal: formatDate(this.state.dataDeFiltroDeAlteracaoDeLocal),
                     gateway: this.state.gateway ? this.state.gateway.mac : null,
                     mac: mac,
                     agrupar: this.state.agrupar,
                  });
               });
            }}
            renderizarFiltros={() => {
               return (
                  <div style={{}}>
                     <Row>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Filler height={10} />

                              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                 <div
                                    style={{
                                       display: 'table-cell',
                                       fontSize: 22,
                                       color: LayoutParams.colors.corSecundaria,
                                    }}
                                 >
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                 </div>
                                 <div style={{ display: 'table-cell', fontSize: 12, paddingTop: 8, paddingLeft: 3 }}>
                                    <span>
                                       {
                                          this.props.lang.telaDeCadastroDeEquipamento
                                             .listagemDosDadosNoMomentoDaAlteracaoDeLocal
                                       }
                                    </span>
                                 </div>
                              </div>
                           </Form.Group>
                        </Col>
                     </Row>

                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{this.props.lang.telaDeCadastroDeEquipamento.data}</Label>
                              <DateInput
                                 defaultValue={this.state.dataDeFiltroDeAlteracaoDeLocal}
                                 onChange={(value) => {
                                    updateState(this, (state) => {
                                       state.dataDeFiltroDeAlteracaoDeLocal = value;
                                    });
                                 }}
                              />
                           </FormGroup>
                        </Col>
                        <Col>
                           <Form.Group style={{ marginBottom: 8 }}>
                              <Label>{this.props.lang.telaDeCadastroDeEquipamento.gateway}</Label>
                              <Select
                                 name={'gateway'}
                                 getKeyValue={(i) => i.id}
                                 getDescription={(i) => i.mac + (i.patrimonio ? ' [' + i.patrimonio + ']' : '')}
                                 onSelect={(i) => {
                                    this.setState({ gateway: i });
                                 }}
                                 formularioPadrao={(select) => {
                                    return <GatewayView api={this.props.api} select={select} lang={this.props.lang} />;
                                 }}
                                 noDropDown={true}
                                 readOnlyColor='#ffff'
                                 getFilterUrl={(text) =>
                                    '/gateway' +
                                    buildQueryString(2, null, 'id', { Searchable: prepareSearchable(text) })
                                 }
                              />
                           </Form.Group>
                        </Col>
                     </Row>

                     <Row>
                        <Col sm={3} md={3} lg={3}>
                           <CheckBox
                              label={this.props.lang.telaDeCadastroDeEquipamento.agrupar}
                              onChange={(checked) => {
                                 this.setState({ agrupar: checked });
                              }}
                           />
                        </Col>
                     </Row>
                     <br />
                  </div>
               );
            }}
         />
      );
   };

   getFiltro = (filtro) => {
      // Pode ser sobrescrito caso seja necessário ter mais algum filtro na tela.
      var result = this.props.filtroExtra ? this.props.filtroExtra() : {};
      if (filtro && filtro.texto) {
         let texto = filtro.texto.toString();
         if (texto[0] === '#') {
            if (!isNumeric(texto.substring(1))) {
               showError(this.props.lang.formularioPadrao.codigoInvalido + ': "' + texto.substring(1) + '"');
               return result;
            }
            result.id = texto.substring(1);
         } else {
            result.searchable = prepareSearchable(texto);
         }
      }

      result.situacao = this.state.filtroDeSituacao;

      return result;
   };

   render() {
      return (
         <Fragment>
            {this.modalHistoricoMonitoramento()}
            <FormularioPadrao
               titulo={this.props.lang.telaDeCadastroDeEquipamento.titulo}
               url={'/equipamento'}
               fastUrl={'/equipamento/fast'}
               api={this.props.api}
               lang={this.props.lang}
               ref={(c) => (this.form = c)}
               ordenacaoPadrao={'nome'}
               permissoes={[1431, 1432, 1433, 1434]}
               getFiltro={this.getFiltro}
               getTitulosDaTabela={this.controller.getTitulosDaTabela}
               getDadosDaTabela={this.controller.getDadosDaTabela}
               getAcoesDaTabela={this.controller.getAcoesDaTabela}
               renderizarFormulario={this.renderizarFormulario}
               getObjetoDeDados={this.controller.getObjetoDeDados}
               antesDeInserir={this.controller.antesDeInserir}
               antesDeEditar={this.controller.antesDeEditar}
               antesDeSalvar={this.controller.antesDeSalvar}
               antesDeExcluir={this.controller.antesDeExcluir}
               getFormState={() => {
                  return this.state;
               }}
               setFormState={(state, callback) => {
                  return new Promise((resolve) => {
                     this.setState(state, () => {
                        resolve();
                        if (callback) {
                           callback();
                        }
                     });
                  });
               }}
               select={this.props.select}
               itemVazio={{
                  situacao: { id: 1 },
               }}
               menus={[
                  {
                     titulo: this.props.lang.telaDeCadastroDeEquipamento.importar,
                     acao: () => this.setState({ irParaImportacao: true }),
                  },
                  {
                     titulo: this.props.lang.telaDeCadastroDeEquipamento.situacao,
                     subMenus: [
                        {
                           conteudo: this.props.lang.formularioPadrao.ativos,
                           acao: () =>
                              updateState(this, (state) => {
                                 state.filtroDeSituacao = 1;
                              }).then(this.form.filtrar()),
                        },
                        {
                           conteudo: this.props.lang.formularioPadrao.inativos,
                           acao: () =>
                              updateState(this, (state) => {
                                 state.filtroDeSituacao = 2;
                              }).then(this.form.filtrar()),
                        },
                        {
                           conteudo: this.props.lang.formularioPadrao.todos,
                           acao: () =>
                              updateState(this, (state) => {
                                 state.filtroDeSituacao = null;
                              }).then(this.form.filtrar()),
                        },
                     ],
                  },
               ]}
            />
            {this.state.irParaImportacao && <Redirect to='/importarequipamentos' />}
         </Fragment>
      );
   }
}

const StyledNav = styled.div`
   .nav-link.active {
      color: ${LayoutParams.colors.corSecundaria};
   }
`;
