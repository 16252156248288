import { showError } from '../../components/Messages';
import { BaseController } from '../BaseController';

export default class ServicoDeNotificacaoController extends BaseController {
   constructor(props) {
      super(props);
      this.name = 'ServicoDeNotificacaoController';
   }

   getTitulosDaTabela = () => {
      return [
         { titulo: this.lang.codigo, orderby: 'Id', className: 'codigo' },
         { titulo: this.lang.telaDeCadastroDeServicoDeNotificacao.nome, width: '100%', orderby: 'nome' },
      ];
   };

   getDadosDaTabela = (item) => {
      return [item.id, item.nome];
   };

   getObjetoDeDados = () => {
      return new Promise((resolve, reject) => {
         let state = this.view.state;
         let item = state.itemSelecionado;

         if (!item.nome) {
            showError(this.lang.telaDeCadastroDeServicoDeNotificacao.mensagens.informeONome);
            reject();
            return;
         }

         var input = {
            nome: item.nome,
            horaInicial: item.horaInicial,
            horaFinal: item.horaFinal,
            configuracoes: item.configuracoes,
         };

         if (state.alterando) {
            input.id = parseInt(item.id);
         }
         resolve(input);
      });
   };
}
