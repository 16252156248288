import { Row, Col, FormGroup } from 'react-bootstrap';
import React, { Component } from 'react';
import FormularioPadrao from '../FormularioPadrao';
import Label from '../../components/Label';
import TextInput from '../../components/TextInput';
import TagController from '../../controllers/Empresa/TagController';
import { updateState } from '../../utils/Functions';
import Select from '../../components/Select';

export default class TagView extends Component {
   constructor(props) {
      super(props);
      this.state = { mostrarAcessos: true };
      this.controller = new TagController(this);
   }

   renderizarFormulario = () => {
      let lang = this.props.lang;
      let tela = lang.telaDeCadastroDeTag;
      return (
         <React.Fragment>
            <Row>
               <Col sm={2} md={2} lg={2}>
                  <FormGroup>
                     <Label>{lang.codigo}</Label>
                     <TextInput readOnly defaultValue={this.state.itemSelecionado.id} />
                  </FormGroup>
               </Col>

               <Col sm={4} md={4} lg={4}>
                  <FormGroup>
                     <Label>{tela.mac}</Label>
                     <TextInput
                        defaultValue={this.state.itemSelecionado.mac}
                        onChange={(e) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.mac = e.target.value;
                           });
                        }}
                        upperCase={true}
                     />
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{tela.situacao}</Label>
                     <Select
                        as='select'
                        name='situacao'
                        defaultValue={this.state.itemSelecionado.situacao && this.state.itemSelecionado.situacao.id}
                        options={[
                           { id: 1, descricao: lang.situacaoDeTag.ativo },
                           { id: 2, descricao: lang.situacaoDeTag.inativo },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.situacao = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>

               <Col>
                  <FormGroup>
                     <Label>{tela.modelo}</Label>
                     <Select
                        as='select'
                        name='modelo'
                        defaultValue={this.state.itemSelecionado.modelo && this.state.itemSelecionado.modelo.id}
                        options={[
                           { id: 1, descricao: 'Moko H1' },
                           { id: 2, descricao: 'Moko H4' },
                           { id: 3, descricao: 'Néos MUT200' },
                           { id: 4, descricao: 'Minew MST01' },
                           { id: 5, descricao: 'Inkbird IBS-TH2-Plus' },
                           { id: 6, descricao: 'Khompe NIR 21ZI' },
                           { id: 7, descricao: 'Khompe NIR 22IR' },
                           { id: 8, descricao: 'Moko LW-009' },
                           { id: 9, descricao: 'Moko M1' },
                        ]}
                        getKeyValue={(i) => i.id}
                        getDescription={(i) => i.descricao}
                        onSelect={(i) => {
                           updateState(this, (state) => {
                              state.itemSelecionado.modelo = i;
                           });
                        }}
                     />
                  </FormGroup>
               </Col>
            </Row>
            <br />
         </React.Fragment>
      );
   };

   render() {
      return (
         <FormularioPadrao
            titulo={this.props.lang.telaDeCadastroDeTag.titulo}
            url={'/tag'}
            api={this.props.api}
            lang={this.props.lang}
            ordenacaoPadrao={'mac'}
            permissoes={[1401, 1402, 1403, 1404]}
            getFiltro={this.controller.getFiltro}
            getTitulosDaTabela={this.controller.getTitulosDaTabela}
            getDadosDaTabela={this.controller.getDadosDaTabela}
            renderizarFormulario={this.renderizarFormulario}
            getObjetoDeDados={this.controller.getObjetoDeDados}
            antesDeInserir={this.controller.antesDeInserir}
            antesDeEditar={this.controller.antesDeEditar}
            antesDeSalvar={this.controller.antesDeSalvar}
            antesDeExcluir={this.controller.antesDeExcluir}
            getFormState={() => {
               return this.state;
            }}
            setFormState={(state, callback) => {
               return new Promise((resolve) => {
                  this.setState(state, () => {
                     resolve();
                     if (callback) {
                        callback();
                     }
                  });
               });
            }}
            select={this.props.select}
            itemVazio={{
               situacao: { id: 1 },
            }}
            form={this.props.form}
         />
      );
   }
}
